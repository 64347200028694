import { Route, Switch } from "react-router-dom";

import Home from "./containers/Home";
import MarqetaUsers from "./containers/admin/MarqetaUsers";
import NotFound from "./containers/NotFound";
import React from "react";
import RoleRequiredRoute from "./components/RoleRequiredRoute";
import Settings from "./containers/Settings";
import SignIn from "./containers/SignIn";
import SignUp from "./containers/SignUp";
import TermsAndConditions from "./containers/admin/TermsAndConditions";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Users from "./containers/admin/Users";
import Payment from "./containers/Payment";
import {Roles} from "@ontick/auth-model";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <UnauthenticatedRoute exact path="/sign-up">
        <SignUp />
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path="/sign-in">
        <SignIn />
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path="/sign-in/:code">
        <SignIn />
      </UnauthenticatedRoute>

      <RoleRequiredRoute
        allowedRoles={Roles.ALL}
        exact
        path="/settings"
      >
        <Settings />
      </RoleRequiredRoute>

      <RoleRequiredRoute
        allowedRoles={[Roles.CARD_CARRIER]}
        exact
        path="/payment"
      >
        <Payment />
      </RoleRequiredRoute>

      <RoleRequiredRoute
        allowedRoles={[Roles.ADMIN]}
        exact
        path="/admin/marqeta-users"
      >
        <MarqetaUsers />
      </RoleRequiredRoute>

      <RoleRequiredRoute
        allowedRoles={[Roles.ADMIN]}
        exact
        path="/admin/users"
      >
        <Users />
      </RoleRequiredRoute>

      <RoleRequiredRoute
        allowedRoles={[Roles.ADMIN]}
        exact
        path="/admin/terms-and-conditions"
      >
        <TermsAndConditions />
      </RoleRequiredRoute>

      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
