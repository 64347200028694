import React, { FormEvent, useEffect } from "react";
import "./SignUp.css";
import { Form, InputGroup } from "react-bootstrap";
import { useTypedFormFields } from "../helpers/useTypedFormFields";
import LoaderButton from "../components/LoaderButton";
import CheckEmail from "../components/CheckEmail";
import { subYears, isBefore } from "date-fns/fp";

import { AppDispatch } from "../redux/store";
import {
  resetAuthCommand,
  signUpCommand,
  RootState,
} from "@ontick/clients-common";
import { connect } from "react-redux";

interface ISignUpFormProps {
  firstname: string;
  lastname: string;
  email: string;
  dateOfBirth: string;
}

type SignUpProps = {
  resetAuthCommandFunc: () => void;

  isSignUpInProgress: boolean;
  hasSentEmail: boolean;
  signUpCommandFunc: (
    email: string,
    firstname: string,
    lastname: string,
    dateOfBirth: number
  ) => void;

  emailAlreadyExists: boolean;
};

const SignUp: React.FC<SignUpProps> = ({
  resetAuthCommandFunc,
  isSignUpInProgress,
  signUpCommandFunc,
  hasSentEmail,
  emailAlreadyExists,
}) => {
  const { fields, changeHandler } = useTypedFormFields<ISignUpFormProps>({
    firstname: "",
    lastname: "",
    email: "",
    dateOfBirth: new Date(1976, 3, 12).toISOString(),
  });

  useEffect(() => {
    function onLoad() {
      resetAuthCommandFunc();
    }
    onLoad();
  }, [resetAuthCommandFunc]);

  const handleSignUpSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    signUpCommandFunc(
      fields.email,
      fields.firstname,
      fields.lastname,
      new Date(fields.dateOfBirth).getTime()
    );
  };

  const validateSignUpForm = () => {
    const minusEighteenYears = subYears(18);
    const now = new Date();
    const eighteenYearsAgo = minusEighteenYears(now);

    return (
      fields.email.length > 0 &&
      fields.firstname.length > 0 &&
      fields.lastname.length > 0 &&
      isBefore(eighteenYearsAgo, new Date(fields.dateOfBirth))
    );
  };

  function renderCheckEmail(showAlreadyExists: boolean, email: string) {
    return (
      <div className="SignUp">
        <CheckEmail showAlreadyExists={showAlreadyExists} email={email} />
      </div>
    );
  }

  const renderSignUpForm = () => {
    return (
      <div className="SignUp">
        <div className="container">
          <Form onSubmit={handleSignUpSubmit}>
            <Form.Group controlId="firstname">
              <Form.Label>firstname</Form.Label>
              <Form.Control
                autoFocus
                type="firstname"
                value={fields.firstname}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group controlId="lastname">
              <Form.Label>lastname</Form.Label>
              <Form.Control
                type="lastname"
                value={fields.lastname}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>email</Form.Label>
              <Form.Control
                type="email"
                value={fields.email}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                date of birth (you have to be over 18 to sign up to Ontick)
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id="dateOfBirth"
                  type="date"
                  value={fields.dateOfBirth}
                  onChange={changeHandler}
                />

                <Form.Control.Feedback type="invalid">
                  you have to be over 18 to sign up to Ontick
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <LoaderButton
              block
              size="lg"
              type="submit"
              variant="success"
              isLoading={isSignUpInProgress}
              disabled={!validateSignUpForm()}
            >
              sign up
            </LoaderButton>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      {hasSentEmail
        ? renderCheckEmail(emailAlreadyExists, fields.email)
        : renderSignUpForm()}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    authState: { error, isSignUpInProgress, hasSentEmail },
    authTransientState: { emailAlreadyExists },
  } = state;

  return {
    isSignUpInProgress,
    hasSentEmail,
    error,
    emailAlreadyExists,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetAuthCommandFunc: () => dispatch(resetAuthCommand()),
  signUpCommandFunc: (
    email: string,
    firstname: string,
    lastname: string,
    dateOfBirth: number
  ) => dispatch(signUpCommand({ email, firstname, lastname, dateOfBirth })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
