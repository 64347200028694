"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountingSettings = void 0;
const accountingSettings = {
    currency: {
        symbol: "£",
    },
    number: {
        precision: 2,
    },
};
exports.accountingSettings = accountingSettings;
