import { IApiAdapter } from "@ontick/clients-common";
import { API, graphqlOperation } from "aws-amplify";
import { from } from "rxjs";

const apiAdapter: IApiAdapter = {
  apiGetAsync: (apiName: string, path: string, init: any) =>
    API.get(apiName, path, init),
  apiPostAsync: (apiName: string, path: string, init: any) =>
    API.post(apiName, path, init),
  // aws use zen-observable, and this graphql method returns a
  // Promise|Oservable(zen) union type. We we force this to a promise,
  // and then use 'from' from RxJs to convert that to an Rx Observable.
  // Yeeeeaaah...
  graphql$: (query: any, variables?: {} | undefined) =>
    from(
      (API.graphql(
        graphqlOperation(query, variables)
      ) as unknown) as Promise<any>
    ),

  graphqlAsync: (query: any, variables?: {} | undefined) =>
    (API.graphql(
      graphqlOperation(query, variables)
    ) as unknown) as Promise<any>,
};

export { apiAdapter };
