import React, { FormEvent, useState } from "react";

import { useAuthContext } from "./AuthContext";

import "./SimulatePayment.css";

import { Form } from "react-bootstrap";
import { useTypedFormFields } from "../helpers/useTypedFormFields";
import LoaderButton from "./LoaderButton";
import { TransactionModel } from "@ontick/marqeta-api";
import { API } from "aws-amplify";

interface ISimulatePaymentFormProps {
  amount: string;
  cardToken: string;
}

interface IResponse {
  data: TransactionModel;
}

const SimulatePayment: React.FC = () => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { user } = useAuthContext();

  const { fields, changeHandler } =
    useTypedFormFields<ISimulatePaymentFormProps>({
      amount: "10.00",
      cardToken: "85a2fc3b-6f9e-4497-83bb-2be5e986f23e",
    });

  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingForm(true);

    try {
      const r = (await API.post(
        "marqeta-admin",
        "/marqeta-admin/simulate-authorization",
        {
          body: { cardToken: fields.cardToken, amount: fields.amount },
        }
      )) as IResponse;

      console.log("result: ", r);

      //setIsSubmittingForm(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmittingForm(false);
    }
  };

  const validateCreatePaymentForm = () => {
    return fields.amount.length > 0;
  };

  return (
    <div className="SimulatePayment">
      <div className="container">
        <Form onSubmit={handleOnSubmitAsync}>
          <Form.Group controlId="cardToken">
            <Form.Label>card token</Form.Label>
            <Form.Control
              autoFocus
              type="cardToken"
              value={fields.cardToken}
              onChange={changeHandler}
            />
          </Form.Group>
          <Form.Group controlId="amount">
            <Form.Label>amount</Form.Label>
            <Form.Control
              autoFocus
              type="amount"
              value={fields.amount}
              onChange={changeHandler}
            />
          </Form.Group>

          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="success"
            isLoading={isSubmittingForm}
            disabled={!validateCreatePaymentForm()}
          >
            create
          </LoaderButton>
        </Form>
      </div>
    </div>
  );
};

export default SimulatePayment;
