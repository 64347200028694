"use strict";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
Object.defineProperty(exports, "__esModule", { value: true });
exports.payment = exports.user = void 0;
exports.user = `
  subscription User($id: String!) {
    user(id: $id) {
      id
      firstname
      lastname
      email
      dateOfBirth
      created
      updated
      currentVersion
      termsAndConditions {
        id
        timestamp
      }
      kyc {
        status
        timestamp
      }
      marqeta {
        userToken
        cardToken
        cardActivated
        timestamp
      }
    }
  }
`;
exports.payment = `
  subscription Payment($id: String!) {
    payment(id: $id) {
      id
      amount
      value {
        amount
        currency
      }
      type
      members {
        id
        firstname
        lastname
        email
        share {
          amount
          currency
        }
      }
      status
      created
      updated
      completed
      currentVersion
      isCreator
    }
  }
`;
