"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDelayPollUserMiddleware = void 0;
const user_slice_1 = require("./user.slice");
const createDelayPollUserMiddleware = (ms) => {
    const delayPollUserMiddleware = ({ dispatch, getState, }) => (next) => (action) => {
        if (action.type === user_slice_1.pollForNewUserCommand("").type) {
            // wait for the backend to do its thing...
            // console.log("before snooze")
            // await snoozeAsync(ms);
            // console.log("after snooze")
            // const {
            //   userState: { user },
            // } = getState();
            // if (user) {
            //   return;
            // } else {
            //   dispatch(loadUserCommand(action.payload));
            //   dispatch(pollForNewUserCommand(action.payload));
            // }
            console.log("timeout: ", ms);
            setTimeout(() => {
                const { userState: { user }, } = getState();
                if (user) {
                    return;
                }
                else {
                    dispatch(user_slice_1.loadUserCommand(action.payload));
                    dispatch(user_slice_1.pollForNewUserCommand(action.payload));
                }
                next(action);
            }, ms);
        }
        next(action);
    };
    return delayPollUserMiddleware;
};
exports.createDelayPollUserMiddleware = createDelayPollUserMiddleware;
