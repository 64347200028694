"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapItemToUserModel = void 0;
const mapItemToUserModel = (user) => {
    let tcs;
    if (user.termsAndConditions) {
        tcs = {
            id: user.termsAndConditions.id,
            timestamp: parseInt(user.termsAndConditions.timestamp),
        };
    }
    else {
        tcs = undefined;
    }
    let kyc;
    if (user.kyc) {
        kyc = {
            status: user.kyc.status,
            timestamp: user.kyc.timestamp,
        };
    }
    let marqeta;
    if (user.marqeta) {
        marqeta = {
            userToken: user.marqeta.userToken,
            cardToken: user.marqeta.cardToken,
            timestamp: user.marqeta.timestamp,
            cardActivated: user.marqeta.cardActivated,
        };
    }
    const userModel = {
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        dateOfBirth: parseInt(user.dateOfBirth),
        created: parseInt(user.created),
        updated: parseInt(user.updated),
        type: "",
        currentVersion: user.currentVersion,
        termsAndConditions: tcs,
        kyc: kyc,
        marqeta: marqeta,
    };
    return userModel;
};
exports.mapItemToUserModel = mapItemToUserModel;
