import React from "react";
import { Alert } from "react-bootstrap";

interface ICheckEmailProps {
  showAlreadyExists: boolean
  email: string;
}

const CheckEmail: React.FC<ICheckEmailProps> = ({ email, showAlreadyExists = false,  }) => {
  return (
    <div className="container">
      {showAlreadyExists ? (
        <Alert variant="warning">
          An account with the given email already exists.
        </Alert>
      ) : (
        <></>
      )}

      <Alert variant="primary">
        Please check your email (<b>{email}</b>) for a magic login link!
      </Alert>
    </div>
  );
};

export default CheckEmail;
