import "./MarqetaUsers.css";

import React, { useCallback, useEffect, useState } from "react";

import LoaderButton from "../../components/LoaderButton";
import { Table } from "react-bootstrap";
import { onError } from "../../helpers/onError";
import { ApiManager } from "@ontick/clients-common";
import { CardHolderModel } from "@ontick/marqeta-api";
import { apiAdapter } from "../../redux/apiAdapter";

interface ICardHolderModelWithActivity extends CardHolderModel {
  isActive: boolean;
}

const MarqetaUsers: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  //const [isDeleting, setIsDeleting] = useState(false);
  const [users, setUsers] = useState<ICardHolderModelWithActivity[]>([]);
  const apiManager = new ApiManager(apiAdapter);

  const getUsers = useCallback(() => {
    async function loadUsers() {
      setIsLoading(true);
      try {
        const users = await fetchAllMarqetaUsersAsync();

        setUsers(users);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    loadUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  // async function handleDelete(token: string | undefined, e: MouseEvent) {
  //   if (token === undefined) return;
  //   if (isDeleting) return;

  //   e.preventDefault();
  //   setIsDeleting(true);

  //   // 1. Make a shallow copy of the items
  //   const usersCopy = [...users];

  //   // 2. Make a shallow copy of the item you want to mutate
  //   const updatingUser = usersCopy.filter((x) => {
  //     return x.token === token;
  //   })[0];
  //   const index = usersCopy.indexOf(updatingUser);
  //   //let item = {...usersCopy[1]};
  //   const updatingUserCopy = { ...updatingUser };

  //   // 3. Replace the property you're intested in
  //   //item.name = 'newName';
  //   updatingUserCopy.isActive = true;
  //   // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first

  //   usersCopy[index] = updatingUserCopy;

  //   // 5. Set the state to our new copy
  //   setUsers(usersCopy);

  //   try {
  //     await deleteMarqetaUser(token);
  //     const users = await loadAllMarqetaUsersAsync();
  //     // console.log("users: ", users);
  //     setUsers(users);
  //   } catch (e) {
  //     onError(e);
  //   }
  // }

  // function deleteMarqetaUser(token: string): Promise<any> {
  //   return API.del("user", `/marqeta-users/${token}`, {});
  // }

  const fetchAllMarqetaUsersAsync = async (): Promise<
    ICardHolderModelWithActivity[]
  > => {
    const result = await apiManager.fetchAllMarqetaUsersAsync();
    return result.map((u) => u as ICardHolderModelWithActivity);
  };

  const renderUsers = (
    users: ICardHolderModelWithActivity[]
    //handleDelete: (token: string | undefined, e: MouseEvent) => Promise<void>
  ) => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Token</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {users.map(({ token, first_name, last_name, email }) => (
            <tr key={token}>
              <td>{token}</td>
              <td>{first_name}</td>
              <td>{last_name}</td>
              <td>{email}</td>
              {/* <td>
                <LoaderButton
                  block
                  size="sm"
                  type="submit"
                  isLoading={isActive}
                  onClick={(e: MouseEvent) => handleDelete(token, e)}
                >
                  Delete
                </LoaderButton>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="Users">
      <LoaderButton
        type="submit"
        variant="success"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => getUsers()}
      >
        reload
      </LoaderButton>
      {isLoading ? (
        <h2 className="pb-3 mt-4 mb-3">loading...</h2>
      ) : (
        renderUsers(users)
      )}
    </div>
  );
};

export default MarqetaUsers;
