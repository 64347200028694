import React from "react";
import Button from "react-bootstrap/Button";
import { BsArrowRepeat } from "react-icons/bs";
import "./LoaderButton.css";

type LoaderButtonProps = {
  isLoading: boolean;
  className?: string;
  disabled?: boolean;
  [x: string]: any;
};

const LoaderButton: React.FC<LoaderButtonProps> = ({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) => {
  return (
    <Button
      disabled={disabled || isLoading}
      className={`LoaderButton ${className}`}
      {...props}
    >
      {isLoading && <BsArrowRepeat className="spinning" />}
      {props.children}
    </Button>
  );
};

export default LoaderButton;
