"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Roles = void 0;
class Roles {
}
exports.Roles = Roles;
Roles.UNVERIFIED = "unverified";
Roles.VERIFIED = "verified";
Roles.GUEST_MEMBER = "guest-member";
Roles.CARD_CARRIER = "card-carrier";
Roles.ADMIN = "admin";
Roles.ALL = [
    "unverified",
    "verified",
    "guest-member",
    "card-carrier",
    "admin",
];
