import React from "react";

import { gql, useQuery } from "@apollo/client";
import {
  getPayments,
  GetPaymentsQuery,
  GetPaymentsQueryVariables,
} from "@ontick/appsync-client";

import "./AllPayments.css";
import { Alert, Table } from "react-bootstrap";
import { useAuthContext } from "./AuthContext";
import { Observer } from "rxjs";

interface IAllPaymentsProps {
  paymentIdObserver$: Observer<string>;
}

const AllPayments: React.FC<IAllPaymentsProps> = ({
  paymentIdObserver$: paymentId$,
}) => {
  const { user } = useAuthContext();

  const getPaymentsQuery = gql(getPayments);

  const { loading, error, data } = useQuery<
    GetPaymentsQuery,
    GetPaymentsQueryVariables
  >(getPaymentsQuery, { variables: { userId: user?.id! } });

  const renderTableBody = (d?: GetPaymentsQuery) => {
    if (d) {
      const { getPayments: dta } = d;
      if (dta) {
        // array is frozen in strict mode so we need to copy it before we can sort it
        //https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
        let cpy = dta.slice();
        cpy.sort((a, b) => b.created - a.created);

        return cpy.map(
          ({
            id,
            isCreator,
            value,
            status,
            currentVersion,
            created,
            updated,
            completed,
          }) => (
            <tr key={id}>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => paymentId$.next(id)}
                >
                  {id}
                </a>
              </td>
              <td>{isCreator ? "Creator" : "Member"}</td>
              <td>{`${value?.amount} (${value?.currency}`}</td>
              <td>{status}</td>
              <td>{created}</td>
              <td>{updated}</td>
              <td>{completed}</td>
              <td>{currentVersion}</td>
            </tr>
          )
        );
      }
    }

    return <h3>no payments to displays</h3>;
  };

  return (
    <div className="AllPayments">
      <div className="container">
        {error ? <Alert variant="warning">{error}</Alert> : null}
        {loading ? (
          <Alert variant="warning">LOADING</Alert>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Creator/Member</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Completed</th>
                <th>Version</th>
              </tr>
            </thead>
            <tbody>{renderTableBody(data)}</tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default AllPayments;
