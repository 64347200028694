import React, { FormEvent } from "react";

import { API } from "@aws-amplify/api";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const Settings: React.FC = () => {
  // const history = useHistory();
  // const [isLoading, setIsLoading] = useState(false);
  // const [fields, handleFieldChange] = useFormFields({
  //   name: "",
  // });

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    //setIsLoading(true);

    try {
      await createGroup();
      //history.push("/");
    } catch (e) {
      console.log(e);
      //onError(e);
      //setIsLoading(false);
    }
  }

  function createGroup(): Promise<any> {
    console.log("foo");
    // return API.post("tc", "/terms-and-conditions", { version: "foo", copy: "bar" });
    //return API.get("tc", "/tc", {});
    return API.get("user", "/user", null);
  }

  return (
    <div className="Settings">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control autoFocus type="name" />
        </Form.Group>
        <Button block size="lg" type="submit">
          Create
        </Button>
      </Form>
    </div>
  );
};

export default Settings;
