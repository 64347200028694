import {
  RootState,
  acceptTermsAndConditionsCommand,
} from "@ontick/clients-common";

import { AppDispatch } from "../redux/store";
import LoaderButton from "./LoaderButton";
import { Modal } from "react-bootstrap";
import React from "react";
import { connect } from "react-redux";

import { ITermsAndConditionsModel } from "@ontick/tc-model";

interface ITermsAndConditionsModalProps {
  showTermsAndConditions: boolean;
  termsAndConditions: ITermsAndConditionsModel| undefined;
  acceptTermsAndConditions: (id: string) => void;
  isAcceptingTermsAndConditionsInProgress: boolean;
}

const TermsAndConditionsModal: React.FC<ITermsAndConditionsModalProps> = ({
  showTermsAndConditions,
  termsAndConditions,
  acceptTermsAndConditions,
  isAcceptingTermsAndConditionsInProgress,
}) => {
  return (
    <>
      <Modal
        show={showTermsAndConditions}
        //onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6> You have to accept the Terms and Conditions to continue.</h6>

          <p> {termsAndConditions?.copy}</p>
        </Modal.Body>
        <Modal.Footer>
          <span className="small mr-auto">
            version: <b>{termsAndConditions?.version}</b>
          </span>
          <LoaderButton
            size="sm"
            type="submit"
            variant="success"
            isLoading={isAcceptingTermsAndConditionsInProgress}
            onClick={() => acceptTermsAndConditions(termsAndConditions?.id!)}
          >
            accept & close
          </LoaderButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    termsAndConditionsState: { showTermsAndConditions, termsAndConditions },
    userState: { isAcceptingTermsAndConditionsInProgress },
  } = state;

  return {
    showTermsAndConditions,
    termsAndConditions,
    isAcceptingTermsAndConditionsInProgress,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  acceptTermsAndConditions: (id: string) =>
    dispatch(acceptTermsAndConditionsCommand(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditionsModal);
