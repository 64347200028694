// import * as subscriptions from "../graphql/subscriptions";

// import { API, graphqlOperation } from "aws-amplify";
import { ReactNode, createContext, useContext } from "react";
import { RootState, signOutCommand } from "@ontick/clients-common";

import { AppDispatch } from "../redux/store";
import { IUserModel } from "@ontick/user-model";
import { connect } from "react-redux";

interface IAuthContextProps {
  roles: string[] | undefined;
  user: IUserModel | undefined;
  signOut: () => void;
}

const AuthContext = createContext<IAuthContextProps>({
  roles: undefined,
  user: undefined,
  signOut: () => console.warn("no signOut provider."),
});

interface IAuthContextProviderProps extends IAuthContextProps {
  children: ReactNode;
}

const AuthContextProvider: React.FC<IAuthContextProviderProps> = ({
  roles,
  user,
  signOut,
  children,
}) => {
  const value = {
    roles: roles,
    user: user,
    signOut: signOut,
  };
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => useContext(AuthContext);

export { useAuthContext, AuthContext };

const mapStateToProps = (state: RootState) => {
  const {
    authState: { roles },
    userState: { user },
  } = state;

  return {
    roles,
    user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signOut: () => dispatch(signOutCommand()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthContextProvider);
