"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rxjs_1 = require("rxjs");
const redux_observable_1 = require("redux-observable");
const operators_1 = require("rxjs/operators");
const gql_subscription_slice_1 = require("./gql-subscription/gql-subscription.slice");
const user_slice_1 = require("./user/user.slice");
const bootstrapEpic$ = (action$, rootState$) => {
    return action$.pipe(operators_1.filter((action) => action.type === "bootstrap"), operators_1.withLatestFrom(rootState$), operators_1.map(([_, state]) => {
        const { authState: { userId, isSignUpInProgress }, } = state;
        return {
            userId,
            isSignUpInProgress,
        };
    }), operators_1.switchMap((x) => {
        const { userId, isSignUpInProgress } = x;
        // this seems really sukky!!
        if (userId === undefined)
            return rxjs_1.of({ type: "IGNORE" });
        if (isSignUpInProgress) {
            return rxjs_1.of(gql_subscription_slice_1.connectGqlSubscriptionUserCommand(userId));
        }
        else {
            return rxjs_1.of(gql_subscription_slice_1.connectGqlSubscriptionUserCommand(userId), user_slice_1.loadUserCommand(userId));
        }
    }));
};
exports.default = redux_observable_1.combineEpics(bootstrapEpic$);
