"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const auth_slice_1 = require("./auth.slice");
const initialState = {
    isSignInInProgress: false,
    isHandlingSignInCodeInProgress: false,
    //hasSentEmail: false,
    emailAlreadyExists: false,
    userDoesNotExist: false,
    email: undefined,
    code: undefined,
    isFetchingIdentityIdInProgress: false,
};
const reducer = toolkit_1.createReducer(initialState, (builder) => {
    builder
        .addCase(auth_slice_1.resetAuthCommand, () => {
        return initialState;
    })
        .addCase(auth_slice_1.signUpCommand, (state, action) => {
        return Object.assign(Object.assign({}, state), { 
            //isSignUpInProgress: true,
            email: action.payload.email });
    })
        .addCase(auth_slice_1.signInAndStoreSessionCommand, (state, action) => {
        return Object.assign(Object.assign({}, state), { 
            // if the email already exits then this
            // isn't a sign up...
            emailAlreadyExists: action.payload });
    })
        .addCase(auth_slice_1.signInEmailSentEvent, (state) => {
        return Object.assign({}, state);
    })
        .addCase(auth_slice_1.signUpFailedEvent, (state) => {
        return Object.assign({}, state);
    })
        .addCase(auth_slice_1.handleSignInCodeCommand, (state, action) => {
        return Object.assign(Object.assign({}, state), { isHandlingSignInCodeInProgress: true, code: action.payload });
    })
        .addCase(auth_slice_1.signInCommand, (state, action) => {
        return Object.assign(Object.assign({}, state), { isSignInInProgress: true, userDoesNotExist: false, email: action.payload });
    })
        .addCase(auth_slice_1.signInSucceededEvent, (state) => {
        return Object.assign(Object.assign({}, state), { isSignInInProgress: false });
    })
        .addCase(auth_slice_1.userDoesNotExistEvent, (state) => {
        return Object.assign(Object.assign({}, state), { userDoesNotExist: true, isSignInInProgress: false });
    })
        .addCase(auth_slice_1.signInFailedEvent, (state) => {
        return Object.assign(Object.assign({}, state), { isSignInInProgress: false });
    })
        .addCase(auth_slice_1.signOutSucceededEvent, () => {
        return initialState;
    })
        .addCase(auth_slice_1.fetchIdentityIdCommand, (state) => {
        return Object.assign(Object.assign({}, state), { isFetchingIdentityIdInProgress: true });
    })
        .addCase(auth_slice_1.fetchIdentityIdSucceededEvent, (state) => {
        return Object.assign(Object.assign({}, state), { isFetchingIdentityIdInProgress: false });
    })
        .addCase(auth_slice_1.fetchIdentityIdFailedEvent, (state) => {
        return Object.assign(Object.assign({}, state), { isFetchingIdentityIdInProgress: false });
    });
});
exports.default = reducer;
