"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiManager = void 0;
const user_model_1 = require("@ontick/user-model");
const operators_1 = require("rxjs/operators");
const appsync_client_1 = require("@ontick/appsync-client");
class ApiManager {
    constructor(apiAdapter) {
        this.apiAdapter = apiAdapter;
        this.user$ = (userId) => {
            const variables = {
                id: userId,
            };
            return this.apiAdapter
                .graphql$(appsync_client_1.user, variables)
                .pipe(operators_1.map((x) => {
                const { value: { data: { user }, }, } = x;
                if (user) {
                    const userModel = user_model_1.mapItemToUserModel(user);
                    return userModel;
                }
                return undefined;
            }), 
            // only return if its a usermodel, not undefined
            operators_1.filter((x) => x));
        };
        this.payment$ = (id) => {
            const variables = {
                id: id,
            };
            return this.apiAdapter
                .graphql$(appsync_client_1.payment, variables)
                .pipe(operators_1.map((x) => {
                var _a, _b;
                const { value: { data: { payment }, }, } = x;
                if (payment) {
                    const paymentModel = {
                        id: payment.id,
                        status: payment.status,
                        updated: payment.updated.toString(),
                        completed: (_b = (_a = payment.completed) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : undefined,
                        currentVersion: payment.currentVersion,
                    };
                    return paymentModel;
                }
                return undefined;
            }), 
            //only return if its a paymentModel, not undefined
            operators_1.filter((x) => x));
        };
        this.fetchAddressesAsync = async (postcode) => {
            const result = await this.apiAdapter.apiGetAsync("address", `/address/${postcode}`, null);
            return result.data;
        };
        this.fetchAllUsersAsync = async () => {
            try {
                const result = await this.apiAdapter.graphqlAsync(appsync_client_1.getAllUsers);
                // console.log("result: ", result);
                const { data: { getAllUsers }, } = result;
                return getAllUsers.map((u) => {
                    const user = user_model_1.mapItemToUserModel(u);
                    if (u.roles) {
                        user.roles = [];
                        u.roles.forEach((r) => {
                            var _a;
                            (_a = user.roles) === null || _a === void 0 ? void 0 : _a.push(r);
                        });
                    }
                    return user;
                });
            }
            catch (error) {
                console.log("error: ", error);
                throw error;
            }
        };
        this.fetchAllMarqetaUsersAsync = async () => {
            try {
                const result = await this.apiAdapter.graphqlAsync(appsync_client_1.getAllMarqetaUsers);
                // console.log("result: ", result);
                const { data: { getAllMarqetaUsers }, } = result;
                if (getAllMarqetaUsers) {
                    return getAllMarqetaUsers.map((u) => {
                        var _a, _b, _c, _d;
                        const cardHolder = {
                            token: (_a = u.token) !== null && _a !== void 0 ? _a : undefined,
                            first_name: (_b = u.first_name) !== null && _b !== void 0 ? _b : undefined,
                            last_name: (_c = u.last_name) !== null && _c !== void 0 ? _c : undefined,
                            email: (_d = u.email) !== null && _d !== void 0 ? _d : undefined,
                        };
                        return cardHolder;
                    });
                }
                return [];
            }
            catch (error) {
                console.log("error: ", error);
                throw error;
            }
        };
        this.fetchTermsAndConditionsAsync = async () => {
            try {
                const result = await this.apiAdapter.graphqlAsync(appsync_client_1.getLatestTermsAndConditions);
                const { data: { getLatestTermsAndConditions }, } = result;
                if (getLatestTermsAndConditions) {
                    const termsAndConditionsModel = {
                        id: getLatestTermsAndConditions.id,
                        copy: getLatestTermsAndConditions.copy,
                        created: getLatestTermsAndConditions.created,
                        version: getLatestTermsAndConditions.version,
                    };
                    return termsAndConditionsModel;
                }
                return undefined;
            }
            catch (error) {
                console.log("error: ", error);
                throw error;
            }
        };
        this.createTermsAndConditionsAsync = async (creator, copy, version) => {
            try {
                const input = { creator, copy, version };
                const variables = {
                    input,
                };
                const result = await this.apiAdapter.graphqlAsync(appsync_client_1.createTermsAndConditions, variables);
                const { data } = result;
                return data;
            }
            catch (error) {
                console.log("error: ", error);
                throw error;
            }
        };
        this.fetchUserAsync = async (userId) => {
            const variables = {
                id: userId,
            };
            try {
                const result = await this.apiAdapter.graphqlAsync(appsync_client_1.getUser, variables);
                const { data: { getUser }, } = result;
                if (getUser) {
                    return user_model_1.mapItemToUserModel(getUser);
                }
                return undefined;
            }
            catch (error) {
                console.log("error: ", error);
                throw error;
            }
        };
        this.acceptTermsAndConditionsAsync = async (userId, userVersion, termsAndConditionsId) => {
            const input = {
                userId,
                userVersion,
                termsAndConditionsId,
            };
            const variables = {
                input,
            };
            const result = await this.apiAdapter.graphqlAsync(appsync_client_1.acceptTermsAndConditions, variables);
            const { data } = result;
            return data;
        };
        this.setPushRegistrationAsync = async (userId, _userVersion, pushRegistration) => {
            const { service, token } = pushRegistration;
            const input = {
                userId,
                service,
                token,
            };
            const variables = {
                input,
            };
            const result = await this.apiAdapter.graphqlAsync(appsync_client_1.setPushRegistration, variables);
            const { data } = result;
            return data;
        };
        this.activateCardAsync = async (userId, cardToken) => {
            const input = {
                userId,
                cardToken,
            };
            const variables = {
                input,
            };
            const result = await this.apiAdapter.graphqlAsync(appsync_client_1.activateCard, variables);
            const { data } = result;
            return data;
        };
        this.createPaymentAsync = async (userId, amount) => {
            const input = {
                userId,
                //amount: toFixed(amount, 2),
                value: { amount, currency: appsync_client_1.CurrencyEnum.GBP },
                type: appsync_client_1.PaymentTypeEnum.EVEN_SPLIT,
            };
            const variables = {
                input,
            };
            const result = await this.apiAdapter.graphqlAsync(appsync_client_1.createPayment, variables);
            const { data: { createPayment }, } = result;
            return createPayment;
        };
    }
}
exports.ApiManager = ApiManager;
