import React from "react";

import { gql, useQuery } from "@apollo/client";
import {
  getPayment,
  GetPaymentQuery,
  GetPaymentQueryVariables,
} from "@ontick/appsync-client";

import "./PaymentDeets.css";
import { Alert } from "react-bootstrap";
import { useAuthContext } from "./AuthContext";

interface IPaymentDeetsProps {
  paymentId: string;
}

const PaymentDeets: React.FC<IPaymentDeetsProps> = ({ paymentId }) => {
  const { user } = useAuthContext();

  const getPaymentQuery = gql(getPayment);

  const { loading, error, data } = useQuery<
    GetPaymentQuery,
    GetPaymentQueryVariables
  >(getPaymentQuery, {
    variables: { input: { userId: user?.id!, paymentId: paymentId } },
  });

  return (
    <div className="PaymentDeets">
      <div className="container">
        {error ? <Alert variant="warning">{error}</Alert> : null}
        {loading ? (
          <Alert variant="warning">LOADING</Alert>
        ) : (
          <span>{JSON.stringify(data, null, 2)}</span>
        )}
      </div>
    </div>
  );
};

export default PaymentDeets;
