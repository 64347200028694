"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.analyticsUpateEndpointFailedEvent = exports.analyticsUpateEndpointSucceededEvent = exports.analyticsUpateEndpointCommand = exports.refreshSessionFailedEvent = exports.refreshSessionSucceededEvent = exports.refreshSessionCommand = exports.cognitoIdentityCredentialsClearedEvent = exports.clearCognitoIdentityCredentialsCommand = exports.fetchIdentityIdFailedEvent = exports.fetchIdentityIdSucceededEvent = exports.fetchIdentityIdCommand = exports.signOutFailedEvent = exports.signOutSucceededEvent = exports.signOutCommand = exports.signInFailedEvent = exports.userDoesNotExistEvent = exports.signInSucceededEvent = exports.signInCommand = exports.handleSignInCodeCommand = exports.signUpFailedEvent = exports.signInEmailSentEvent = exports.signInAndStoreSessionCommand = exports.signUpCommand = exports.resetAuthCommand = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    cognitoUser: undefined,
    error: undefined,
    userId: undefined,
    idToken: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    roles: undefined,
    identityId: undefined,
    hasSentEmail: false,
    isSignUpInProgress: false,
};
const authSlice = toolkit_1.createSlice({
    name: "auth",
    initialState,
    reducers: {
        resetAuthCommand: () => {
            return initialState;
        },
        signUpCommand: (state, _action) => {
            return Object.assign(Object.assign({}, state), { isSignUpInProgress: true });
        },
        signInAndStoreSessionCommand: (state, action) => {
            return Object.assign(Object.assign({}, state), { isSignUpInProgress: !action.payload });
        },
        signInEmailSentEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { cognitoUser: action.payload, hasSentEmail: true });
        },
        signUpFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload, isSignUpInProgress: false });
        },
        handleSignInCodeCommand: (state, action) => {
            return Object.assign(Object.assign({}, state), { code: action.payload });
        },
        signInCommand: (state, _action) => {
            return Object.assign({}, state);
        },
        signInSucceededEvent: (state, action) => {
            const { payload: { preferredUsername, roles, idToken, accessToken, refreshToken }, } = action;
            return Object.assign(Object.assign({}, state), { userId: preferredUsername, roles: roles, idToken: idToken, accessToken: accessToken, refreshToken: refreshToken, isSignUpInProgress: false });
        },
        userDoesNotExistEvent: (state) => {
            return Object.assign({}, state);
        },
        signInFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
        signOutCommand: (state) => {
            return Object.assign({}, state);
        },
        signOutSucceededEvent: () => {
            return initialState;
        },
        signOutFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
        fetchIdentityIdCommand: (state) => {
            return Object.assign({}, state);
        },
        fetchIdentityIdSucceededEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { identityId: action.payload });
        },
        fetchIdentityIdFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
        clearCognitoIdentityCredentialsCommand: (state) => {
            return Object.assign({}, state);
        },
        cognitoIdentityCredentialsClearedEvent: (state) => {
            return Object.assign({}, state);
        },
        refreshSessionCommand: (state) => {
            return Object.assign({}, state);
        },
        refreshSessionSucceededEvent: (state, action) => {
            const { payload: { roles, idToken, accessToken, refreshToken }, } = action;
            return Object.assign(Object.assign({}, state), { roles: roles, idToken: idToken, accessToken: accessToken, refreshToken: refreshToken });
        },
        refreshSessionFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
        analyticsUpateEndpointCommand: (state, _action) => {
            return Object.assign({}, state);
        },
        analyticsUpateEndpointSucceededEvent: (state) => {
            return Object.assign({}, state);
        },
        analyticsUpateEndpointFailedEvent: (state, _action) => {
            return Object.assign({}, state);
        },
    },
});
_a = authSlice.actions, exports.resetAuthCommand = _a.resetAuthCommand, exports.signUpCommand = _a.signUpCommand, exports.signInAndStoreSessionCommand = _a.signInAndStoreSessionCommand, exports.signInEmailSentEvent = _a.signInEmailSentEvent, exports.signUpFailedEvent = _a.signUpFailedEvent, exports.handleSignInCodeCommand = _a.handleSignInCodeCommand, exports.signInCommand = _a.signInCommand, exports.signInSucceededEvent = _a.signInSucceededEvent, exports.userDoesNotExistEvent = _a.userDoesNotExistEvent, exports.signInFailedEvent = _a.signInFailedEvent, exports.signOutCommand = _a.signOutCommand, exports.signOutSucceededEvent = _a.signOutSucceededEvent, exports.signOutFailedEvent = _a.signOutFailedEvent, exports.fetchIdentityIdCommand = _a.fetchIdentityIdCommand, exports.fetchIdentityIdSucceededEvent = _a.fetchIdentityIdSucceededEvent, exports.fetchIdentityIdFailedEvent = _a.fetchIdentityIdFailedEvent, exports.clearCognitoIdentityCredentialsCommand = _a.clearCognitoIdentityCredentialsCommand, exports.cognitoIdentityCredentialsClearedEvent = _a.cognitoIdentityCredentialsClearedEvent, exports.refreshSessionCommand = _a.refreshSessionCommand, exports.refreshSessionSucceededEvent = _a.refreshSessionSucceededEvent, exports.refreshSessionFailedEvent = _a.refreshSessionFailedEvent, exports.analyticsUpateEndpointCommand = _a.analyticsUpateEndpointCommand, exports.analyticsUpateEndpointSucceededEvent = _a.analyticsUpateEndpointSucceededEvent, exports.analyticsUpateEndpointFailedEvent = _a.analyticsUpateEndpointFailedEvent;
exports.default = authSlice.reducer;
