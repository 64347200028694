import React, { useState } from "react";

import { gql, useLazyQuery } from "@apollo/client";
import {
  getLiabilities,
  GetLiabilitiesQuery,
  GetLiabilitiesQueryVariables,
} from "@ontick/appsync-client";

import "./PaymentDeets.css";
import { Alert, ListGroup } from "react-bootstrap";
import { useAuthContext } from "./AuthContext";
import LoaderButton from "./LoaderButton";
import LiabilityDeets from "./LiabilityDeets";

const Liabilities: React.FC = () => {
  const { user } = useAuthContext();
  const [show, setShow] = useState(false);

  const [getLiabilitiesQuery, { loading, error, data }] = useLazyQuery<
    GetLiabilitiesQuery,
    GetLiabilitiesQueryVariables
  >(gql(getLiabilities), {
    variables: { input: { userId: user?.id! } },
  });

  const renderList = (d?: GetLiabilitiesQuery) => {
    if (d) {
      const { getLiabilities } = d;
      // return (
      //   <pre>
      //     <code>{JSON.stringify(getLiabilities, null, 2)}</code>
      //   </pre>
      // );

      if (getLiabilities.length > 0) {
        return (
          <ListGroup>
            {getLiabilities.map((l) =>
              l ? (
                <ListGroup.Item>
                  {/* <div className="LiabilityDeets">
                  <div className="container">{l.id} </div>
                </div> */}
                  {l.id}
                  <LiabilityDeets liabilityId={l.id}></LiabilityDeets>
                  {/* {l.id} */}
                </ListGroup.Item>
              ) : (
                <></>
              )
            )}
          </ListGroup>
        );
      }
    }

    return <span>No Liabilites</span>;
  };

  const onClickHandler = () => {
    setShow(!show);
    getLiabilitiesQuery();
  };

  return (
    <div className="Liabilities">
      <div className="container">
        {/* {show ? (
          <LiabilityDeets
            liabilityId={"lbt_1725704f9c8b4ba18918444898faf8cd"}
          ></LiabilityDeets>
        ) : (
          <></>
        )} */}
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={loading}
          onClick={(e: MouseEvent) => onClickHandler()}
        >
          load liabilities
        </LoaderButton>
        {error ? (
          <Alert variant="danger">{JSON.stringify(error, null, 2)}</Alert>
        ) : null}
        {loading ? <Alert variant="warning">LOADING</Alert> : renderList(data)}
      </div>
    </div>
  );
};

export default Liabilities;
