import React, { FormEvent, useState } from "react";

import { gql, useMutation } from "@apollo/client";

import {
  cancelPayment as cnclPayment,
  getPayments,
  CancelPaymentMutation,
  CancelPaymentMutationVariables,
} from "@ontick/appsync-client";

import { useAuthContext } from "./AuthContext";

import "./CancelPayment.css";

import LoaderButton from "./LoaderButton";

interface ICreatePaymentProps {
  paymentId: string;
}

const CancelPayment: React.FC<ICreatePaymentProps> = ({ paymentId }) => {
  const { user } = useAuthContext();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const cancelPaymentMutation = gql(cnclPayment);

  const [
    cancelPayment,
    { error: cancelPaymentError, data: cancelPaymentData },
  ] = useMutation<CancelPaymentMutation, CancelPaymentMutationVariables>(
    cancelPaymentMutation,
    {
      variables: { input: { paymentId: paymentId, userId: user?.id! } },
      refetchQueries: [
        // this is declared twice, in the list as well...
        { query: gql(getPayments), variables: { userId: user?.id } },
      ],
    }
  );

  const handleCancelAsync = async (event: any) => {
    event.preventDefault();

    setIsSubmittingForm(true);
    const result = await cancelPayment();
    console.log("result: ", result);

    setIsSubmittingForm(false);
  };

  return (
    <div className="CancelPayment">
      <div className="container">
        {cancelPaymentError ? <p>Oh no! {cancelPaymentError.message}</p> : null}
        {cancelPaymentData && cancelPaymentData.cancelPayment ? (
          <p>Cancelled! - {cancelPaymentData.cancelPayment}</p>
        ) : null}

        <LoaderButton
          block
          size="lg"
          variant="success"
          isLoading={isSubmittingForm}
          onClick={(e: any) => {
            handleCancelAsync(e);
          }}
        >
          cancel
        </LoaderButton>
      </div>
    </div>
  );
};

export default CancelPayment;
