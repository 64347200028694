"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rxjs_1 = require("rxjs");
const user_slice_1 = require("./user.slice");
const operators_1 = require("rxjs/operators");
const ApiManager_1 = require("../../ApiManager");
const redux_observable_1 = require("redux-observable");
const loadUserCommandEpic$ = (action$, _rootState$, { apiAdapter }) => {
    const { fetchUserAsync } = new ApiManager_1.ApiManager(apiAdapter);
    return action$.pipe(operators_1.filter((action) => user_slice_1.loadUserCommand.match(action)), operators_1.mergeMap((action) => {
        return rxjs_1.from(fetchUserAsync(action.payload)).pipe(operators_1.map((result) => {
            if (result)
                return user_slice_1.loadUserSucceededEvent(result);
            else
                return user_slice_1.loadUserFailedEvent("not found");
        }), operators_1.catchError((error) => {
            return rxjs_1.of(user_slice_1.loadUserFailedEvent(JSON.stringify(error)));
        }));
    }));
};
// const pollForNewUserCommandEpic$ = (
//   action$: Observable<PayloadAction<string>>,
//   rootState$: Observable<RootState>
// ) => {
//   console.log("in pollForNewUserCommandEpic");
//   return action$.pipe(
//     filter((action) => pollForNewUserCommand.match(action)),
//     withLatestFrom(rootState$),
//     map(([action, state]) => {
//       const {
//         userState: { user },
//       } = state;
//       return {
//         userId: action.payload,
//         userExists: user !== undefined,
//       };
//     }),
//     mergeMap((x) => {
//       const { userExists, userId } = x;
//       if (userExists) {
//         return of(ignoreElements());
//       }
//       //return of(loadUserCommand(userId), pollForNewUserCommand(userId));
//       return of(pollForNewUserCommand(userId));
//     })
//   );
// };
const acceptTermsAndConditionsCommandEpic$ = (action$, rootState$, { apiAdapter }) => {
    const apiManager = new ApiManager_1.ApiManager(apiAdapter);
    return action$.pipe(operators_1.filter((action) => user_slice_1.acceptTermsAndConditionsCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([action, state]) => {
        const { userState: { user }, } = state;
        return {
            userId: user === null || user === void 0 ? void 0 : user.id,
            userVersion: user === null || user === void 0 ? void 0 : user.currentVersion,
            termsAndConditionsId: action.payload,
        };
    }), operators_1.mergeMap((x) => {
        const { userId, userVersion, termsAndConditionsId } = x;
        return rxjs_1.from(apiManager.acceptTermsAndConditionsAsync(userId, userVersion, termsAndConditionsId)).pipe(operators_1.map(() => {
            return user_slice_1.acceptTermsAndConditionsSucceededEvent();
        }), operators_1.catchError((error) => {
            return rxjs_1.of(user_slice_1.acceptTermsAndConditionsFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const activateCardCommandEpic$ = (action$, rootState$, { apiAdapter }) => {
    const apiManager = new ApiManager_1.ApiManager(apiAdapter);
    return action$.pipe(operators_1.filter((action) => user_slice_1.activateCardCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([action, state]) => {
        const { userState: { user }, } = state;
        const { payload } = action;
        const parts = payload.split("/");
        const token = parts.pop();
        return {
            userId: user === null || user === void 0 ? void 0 : user.id,
            cardToken: token,
        };
    }), operators_1.mergeMap((x) => {
        const { userId, cardToken } = x;
        return rxjs_1.from(apiManager.activateCardAsync(userId, cardToken)).pipe(operators_1.map(() => {
            return user_slice_1.activateCardStartedEvent();
        }), operators_1.catchError((error) => {
            return rxjs_1.of(user_slice_1.activateCardFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const setPushRegistrationCommandEpic$ = (action$, rootState$, { apiAdapter }) => {
    const apiManager = new ApiManager_1.ApiManager(apiAdapter);
    return action$.pipe(operators_1.filter((action) => user_slice_1.setPushRegistrationCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([action, state]) => {
        const { userState: { user }, } = state;
        const { payload } = action;
        return {
            userId: user === null || user === void 0 ? void 0 : user.id,
            userVersion: user === null || user === void 0 ? void 0 : user.currentVersion,
            pushRegistration: payload,
        };
    }), operators_1.mergeMap((x) => {
        const { userId, userVersion, pushRegistration } = x;
        return rxjs_1.from(apiManager.setPushRegistrationAsync(userId, userVersion, pushRegistration)).pipe(operators_1.map(() => {
            return user_slice_1.setPushRegistrationSucceededEvent();
        }), operators_1.catchError((error) => {
            return rxjs_1.of(user_slice_1.setPushRegistrationFailedEvent(JSON.stringify(error)));
        }));
    }));
};
exports.default = redux_observable_1.combineEpics(loadUserCommandEpic$, acceptTermsAndConditionsCommandEpic$, setPushRegistrationCommandEpic$, activateCardCommandEpic$);
