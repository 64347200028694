"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.noEvent = exports.paymentExpired = exports.readyToAcceptMembersEvent = exports.paymentPushedEvent = exports.createPaymentFailedEvent = exports.createPaymentSucceededEvent = exports.createPaymentCommand = exports.resetPaymentStateCommand = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    creating: false,
    created: false,
    paymentId: undefined,
    status: undefined,
    amount: undefined,
    expired: false,
    error: undefined,
};
const paymentSlice = toolkit_1.createSlice({
    name: "payment",
    initialState,
    reducers: {
        resetPaymentStateCommand: () => {
            return Object.assign({}, initialState);
        },
        createPaymentCommand: (state, action) => {
            return Object.assign(Object.assign({}, state), { creating: true, amount: action.payload });
        },
        createPaymentSucceededEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { paymentId: action.payload });
        },
        createPaymentFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
        paymentPushedEvent: (state, _action) => {
            return Object.assign({}, state);
        },
        readyToAcceptMembersEvent: (state) => {
            return Object.assign(Object.assign({}, state), { creating: false, created: true });
        },
        paymentExpired: (state) => {
            return Object.assign(Object.assign({}, state), { expired: false });
        },
        noEvent: (state) => {
            return Object.assign({}, state);
        },
    },
});
_a = paymentSlice.actions, exports.resetPaymentStateCommand = _a.resetPaymentStateCommand, exports.createPaymentCommand = _a.createPaymentCommand, exports.createPaymentSucceededEvent = _a.createPaymentSucceededEvent, exports.createPaymentFailedEvent = _a.createPaymentFailedEvent, exports.paymentPushedEvent = _a.paymentPushedEvent, exports.readyToAcceptMembersEvent = _a.readyToAcceptMembersEvent, exports.paymentExpired = _a.paymentExpired, exports.noEvent = _a.noEvent;
exports.default = paymentSlice.reducer;
