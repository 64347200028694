"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_observable_1 = require("redux-observable");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const ApiManager_1 = require("../../ApiManager");
const payment_slice_1 = require("./payment.slice");
const gql_subscription_slice_1 = require("../gql-subscription/gql-subscription.slice");
const createPaymentCommandEpic$ = (action$, rootState$, { apiAdapter }) => {
    const apiManager = new ApiManager_1.ApiManager(apiAdapter);
    return action$.pipe(operators_1.filter((action) => payment_slice_1.createPaymentCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([action, state]) => {
        const { userState: { user }, } = state;
        return {
            userId: user.id,
            amount: action.payload,
        };
    }), operators_1.mergeMap((x) => {
        const { userId, amount } = x;
        return rxjs_1.from(apiManager.createPaymentAsync(userId, amount.toString())).pipe(operators_1.mergeMap((result) => {
            return rxjs_1.of(payment_slice_1.createPaymentSucceededEvent(result), gql_subscription_slice_1.connectGqlSubscriptionPaymentCommand(result));
        }), operators_1.catchError((error) => {
            return rxjs_1.of(payment_slice_1.createPaymentFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const paymentPushedEventEpic$ = (action$, _rootState$, _) => {
    return action$.pipe(operators_1.filter((action) => payment_slice_1.paymentPushedEvent.match(action)), operators_1.map((action) => {
        const { payload } = action;
        if (payload.status === "WAITING_FOR_MEMBERS") {
            return payment_slice_1.readyToAcceptMembersEvent();
        }
        if (payload.status === "EXPIRED") {
            return payment_slice_1.paymentExpired();
        }
        return payment_slice_1.noEvent();
    }));
};
exports.default = redux_observable_1.combineEpics(createPaymentCommandEpic$, paymentPushedEventEpic$);
