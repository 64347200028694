// // https://4ert790cy1.execute-api.eu-west-2.amazonaws.com/dev
const endpointFactory = (
  apiGatewayId: string,
  region: string,
  stage: string,
  version: string = "v1.0"
): string => {
  return `https://${apiGatewayId}.execute-api.${region}.amazonaws.com/${stage}/${version}`;
};

export { endpointFactory };
