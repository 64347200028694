import React from "react";

import { gql, useQuery } from "@apollo/client";
import {
  getLiability,
  GetLiabilityQuery,
  GetLiabilityQueryVariables,
} from "@ontick/appsync-client";

import "./LiabilityDeets.css";
import { Alert } from "react-bootstrap";
import { useAuthContext } from "./AuthContext";

interface ILiabilityDeetsProps {
  liabilityId: string;
}

const LiabilityDeets: React.FC<ILiabilityDeetsProps> = ({ liabilityId }) => {
  const { user } = useAuthContext();

  const { loading, error, data } = useQuery<
    GetLiabilityQuery,
    GetLiabilityQueryVariables
  >(gql(getLiability), {
    variables: { input: { userId: user?.id!, liabilityId: liabilityId } },
  });

  return (
    <div className="LiabilityDeets">
      <div className="container">
        {error ? (
          <Alert variant="danger">{JSON.stringify(error, null, 2)}</Alert>
        ) : null}
        {loading ? (
          <Alert variant="warning">LOADING</Alert>
        ) : (
          <span>{JSON.stringify(data, null, 2)}</span>
        )}
      </div>
    </div>
  );
};

export default LiabilityDeets;
