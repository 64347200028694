"use strict";
/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTypeEnum = exports.CurrencyEnum = void 0;
var CurrencyEnum;
(function (CurrencyEnum) {
    CurrencyEnum["GBP"] = "GBP";
})(CurrencyEnum = exports.CurrencyEnum || (exports.CurrencyEnum = {}));
var PaymentTypeEnum;
(function (PaymentTypeEnum) {
    PaymentTypeEnum["EVEN_SPLIT"] = "EVEN_SPLIT";
    PaymentTypeEnum["INDIVIDUAL_AMOUNT"] = "INDIVIDUAL_AMOUNT";
})(PaymentTypeEnum = exports.PaymentTypeEnum || (exports.PaymentTypeEnum = {}));
