import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "./AuthContext";

import { IRouteProps } from "./IRouteProps";

const UnauthenticatedRoute: React.FC<IRouteProps> = ({ children, ...rest }) => {
  const { roles } = useAuthContext();
  const redirect = querystring("redirect");

  function querystring(name: string, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  return (
    <Route {...rest}>
      {!roles ? (
        children
      ) : (
        <Redirect to={redirect === "" || redirect === null ? "/" : redirect} />
      )}
    </Route>
  );
};

export default UnauthenticatedRoute;
