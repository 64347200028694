"use strict";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
Object.defineProperty(exports, "__esModule", { value: true });
exports.leavePayment = exports.joinPayment = exports.cancelPayment = exports.createPayment = exports.activateCard = exports.setPushRegistration = exports.acceptTermsAndConditions = exports.createTermsAndConditions = exports.updatePaymentRemoveMember = exports.updatePaymentAddMember = exports.updatePaymentMembers = exports.updatePayment = exports.updateUser = exports.createUser = void 0;
exports.createUser = `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      firstname
      lastname
      email
      dateOfBirth
      created
      updated
      currentVersion
      termsAndConditions {
        id
        timestamp
      }
      kyc {
        status
        timestamp
      }
      marqeta {
        userToken
        cardToken
        cardActivated
        timestamp
      }
    }
  }
`;
exports.updateUser = `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstname
      lastname
      email
      dateOfBirth
      created
      updated
      currentVersion
      termsAndConditions {
        id
        timestamp
      }
      kyc {
        status
        timestamp
      }
      marqeta {
        userToken
        cardToken
        cardActivated
        timestamp
      }
    }
  }
`;
exports.updatePayment = `
  mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      id
      amount
      value {
        amount
        currency
      }
      type
      members {
        id
        firstname
        lastname
        email
        share {
          amount
          currency
        }
      }
      status
      created
      updated
      completed
      currentVersion
      isCreator
    }
  }
`;
exports.updatePaymentMembers = `
  mutation UpdatePaymentMembers($input: UpdatePaymentMembersInput!) {
    updatePaymentMembers(input: $input) {
      id
      amount
      value {
        amount
        currency
      }
      type
      members {
        id
        firstname
        lastname
        email
        share {
          amount
          currency
        }
      }
      status
      created
      updated
      completed
      currentVersion
      isCreator
    }
  }
`;
exports.updatePaymentAddMember = `
  mutation UpdatePaymentAddMember($input: UpdatePaymentAddMemberInput!) {
    updatePaymentAddMember(input: $input) {
      id
      amount
      value {
        amount
        currency
      }
      type
      members {
        id
        firstname
        lastname
        email
        share {
          amount
          currency
        }
      }
      status
      created
      updated
      completed
      currentVersion
      isCreator
    }
  }
`;
exports.updatePaymentRemoveMember = `
  mutation UpdatePaymentRemoveMember($input: UpdatePaymentRemoveMemberInput!) {
    updatePaymentRemoveMember(input: $input) {
      id
      amount
      value {
        amount
        currency
      }
      type
      members {
        id
        firstname
        lastname
        email
        share {
          amount
          currency
        }
      }
      status
      created
      updated
      completed
      currentVersion
      isCreator
    }
  }
`;
exports.createTermsAndConditions = `
  mutation CreateTermsAndConditions($input: CreateTermsAndConditionsInput!) {
    createTermsAndConditions(input: $input)
  }
`;
exports.acceptTermsAndConditions = `
  mutation AcceptTermsAndConditions($input: AcceptTermsAndConditionsInput!) {
    acceptTermsAndConditions(input: $input)
  }
`;
exports.setPushRegistration = `
  mutation SetPushRegistration($input: SetPushRegistrationInput!) {
    setPushRegistration(input: $input)
  }
`;
exports.activateCard = `
  mutation ActivateCard($input: ActivateCardInput!) {
    activateCard(input: $input)
  }
`;
exports.createPayment = `
  mutation CreatePayment($input: CreatePaymentInput) {
    createPayment(input: $input)
  }
`;
exports.cancelPayment = `
  mutation CancelPayment($input: CancelPaymentInput) {
    cancelPayment(input: $input)
  }
`;
exports.joinPayment = `
  mutation JoinPayment($input: JoinPaymentInput) {
    joinPayment(input: $input)
  }
`;
exports.leavePayment = `
  mutation LeavePayment($input: LeavePaymentInput) {
    leavePayment(input: $input)
  }
`;
