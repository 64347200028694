import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "./AuthContext";

import { IRouteProps } from "./IRouteProps";

interface IRoleRouteProps extends IRouteProps {
  readonly allowedRoles: string[];
}

const RoleRequiredRoute: React.FC<IRoleRouteProps> = ({
  allowedRoles,
  children,
  ...rest
}) => {
  const { roles } = useAuthContext();

  function isAllowed(roles?: string[]): boolean {
    if (roles) {
      return roles.some((r) => allowedRoles.includes(r));
    }
    return false;
  }

  return (
    <Route {...rest}>
      {isAllowed(roles) ? children : <Redirect to={`/`} />}
    </Route>
  );
};

export default RoleRequiredRoute;
