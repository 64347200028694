"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.activateCardFailedEvent = exports.activateCardStartedEvent = exports.activateCardCommand = exports.setPushRegistrationSucceededEvent = exports.setPushRegistrationFailedEvent = exports.setPushRegistrationCommand = exports.acceptTermsAndConditionsFailedEvent = exports.acceptTermsAndConditionsSucceededEvent = exports.acceptTermsAndConditionsCommand = exports.pollForNewUserCommand = exports.userPushedEvent = exports.loadUserFailedEvent = exports.loadUserSucceededEvent = exports.loadUserCommand = exports.resetUserStateCommand = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    user: undefined,
    error: undefined,
    isAcceptingTermsAndConditionsInProgress: false,
    pushRegistration: undefined,
};
const userSlice = toolkit_1.createSlice({
    name: "user",
    initialState,
    reducers: {
        resetUserStateCommand: () => {
            return Object.assign({}, initialState);
        },
        loadUserCommand: (state, _action) => {
            return Object.assign({}, state);
        },
        loadUserSucceededEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { user: action.payload });
        },
        loadUserFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
        userPushedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { user: action.payload });
        },
        acceptTermsAndConditionsCommand: (state, _action) => {
            return Object.assign(Object.assign({}, state), { isAcceptingTermsAndConditionsInProgress: true });
        },
        acceptTermsAndConditionsSucceededEvent: (state) => {
            return Object.assign(Object.assign({}, state), { isAcceptingTermsAndConditionsInProgress: false });
        },
        acceptTermsAndConditionsFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload, isAcceptingTermsAndConditionsInProgress: false });
        },
        setPushRegistrationCommand: (state, action) => {
            return Object.assign(Object.assign({}, state), { pushRegistration: action.payload });
        },
        setPushRegistrationSucceededEvent: (state) => {
            return Object.assign({}, state);
        },
        setPushRegistrationFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
        pollForNewUserCommand: (state, _action) => {
            return Object.assign({}, state);
        },
        activateCardCommand: (state, _action) => {
            return Object.assign({}, state);
        },
        activateCardStartedEvent: (state) => {
            return Object.assign({}, state);
        },
        activateCardFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
    },
});
_a = userSlice.actions, exports.resetUserStateCommand = _a.resetUserStateCommand, exports.loadUserCommand = _a.loadUserCommand, exports.loadUserSucceededEvent = _a.loadUserSucceededEvent, exports.loadUserFailedEvent = _a.loadUserFailedEvent, exports.userPushedEvent = _a.userPushedEvent, exports.pollForNewUserCommand = _a.pollForNewUserCommand, exports.acceptTermsAndConditionsCommand = _a.acceptTermsAndConditionsCommand, exports.acceptTermsAndConditionsSucceededEvent = _a.acceptTermsAndConditionsSucceededEvent, exports.acceptTermsAndConditionsFailedEvent = _a.acceptTermsAndConditionsFailedEvent, exports.setPushRegistrationCommand = _a.setPushRegistrationCommand, exports.setPushRegistrationFailedEvent = _a.setPushRegistrationFailedEvent, exports.setPushRegistrationSucceededEvent = _a.setPushRegistrationSucceededEvent, exports.activateCardCommand = _a.activateCardCommand, exports.activateCardStartedEvent = _a.activateCardStartedEvent, exports.activateCardFailedEvent = _a.activateCardFailedEvent;
exports.default = userSlice.reducer;
