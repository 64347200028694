import React, { MouseEvent } from "react";
import "./Home.css";
import Button from "react-bootstrap/Button";

import {
  refreshSessionCommand,
  createPaymentCommand,
} from "@ontick/clients-common";

import { AppDispatch } from "../redux/store";
import { connect } from "react-redux";
import CreatePaymentComponent from "../components/CreatePayment";
import { useAuthContext } from "../components/AuthContext";
import ActivateCard from "../components/ActivateCard";
import ActivePayment from "../components/ActivePayment";
import JoinPayment from "../components/JoinPayment";
import LeavePayment from "../components/LeavePayment";
import SimulatePayment from "../components/SimulatePayment";
import Liabilities from "../components/Liabilities";
import { Roles } from "@ontick/auth-model";

interface IHomeProps {
  refreshSessionCommandFunc: () => void;
  createPaymentCommandFunc: (amount: number) => void;
}

const Home: React.FC<IHomeProps> = ({
  refreshSessionCommandFunc,
  createPaymentCommandFunc,
}) => {
  const { user, roles } = useAuthContext();

  const refreshSessionClick = (event: MouseEvent) => {
    event.preventDefault();
    refreshSessionCommandFunc();
  };

  const createPayment = async (event: MouseEvent) => {
    event.preventDefault();

    createPaymentCommandFunc(10.0);

    // try {
    //   const r = await apiManager.createPaymentAsync(user?.id!, 10.0);
    //   const cp = r.createPayment;
    //   console.log("result: ", cp);

    //   const s = apiManager.payment$(cp).subscribe({
    //     next: (payment) => {
    //       console.log(payment);
    //     },
    //     error: (e) => {
    //       console.log(e);
    //     },
    //   });

    //   console.log("subscription: ", s);
    // } catch (error) {
    //   //onError(JSON.stringify(error));
    //   console.error(error);
    // }
  };

  const renderAdminFunctionality = () => {
    if (roles?.includes(Roles.ADMIN)) {
      return (
        <>
          {/* <LeavePayment></LeavePayment>
        <JoinPayment></JoinPayment>
        <ActivePayment></ActivePayment>
        <ActivateCard></ActivateCard>
  
        <Button onClick={(e: MouseEvent) => refreshSessionClick(e)}>
          Refresh Session
        </Button>
  
        <Button onClick={(e: MouseEvent) => createPayment(e)}>
          Create Payment
        </Button> */}
          <Liabilities></Liabilities>
          <SimulatePayment></SimulatePayment>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {renderAdminFunctionality()}

      <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
        <div className="col-md-5 p-lg-5 mx-auto my-5">
          <h1 className="display-4 font-weight-normal">Punny headline</h1>
          <p className="lead font-weight-normal">
            And an even wittier subheading to boot. Jumpstart your marketing
            efforts with this example based on Apple's marketing pages.
          </p>
          <a className="btn btn-outline-secondary" href="/">
            Coming soon
          </a>
        </div>
        <div className="product-device box-shadow d-none d-md-block"></div>
        <div className="product-device product-device-2 box-shadow d-none d-md-block"></div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  refreshSessionCommandFunc: () => dispatch(refreshSessionCommand()),
  createPaymentCommandFunc: (amount: number) =>
    dispatch(createPaymentCommand(amount)),
});

export default connect(null, mapDispatchToProps)(Home);
