import React, { FormEvent, useEffect } from "react";
import "./SignIn.css";
import { Alert, Form } from "react-bootstrap";
import { useTypedFormFields } from "../helpers/useTypedFormFields";
import LoaderButton from "../components/LoaderButton";
import { useParams } from "react-router-dom";
import CheckEmail from "../components/CheckEmail";
import { AppDispatch } from "../redux/store";

import { connect } from "react-redux";
import {
  handleSignInCodeCommand,
  signInCommand,
  RootState,
} from "@ontick/clients-common";

interface ISignInFormProps {
  email: string;
}

type ParamProps = {
  code?: string;
};

type SignInProps = {
  handleSignInCodeCommandFunc: (code: string) => void;
  hasSentEmail: boolean;
  eml: string;
  signInCommandFunc: (email: string) => void;
  isSignInInProgress: boolean;
  userDoesNotExist: boolean;
  isHandlingSignInCodeInProgress: boolean;
};

const SignIn: React.FC<SignInProps> = ({
  handleSignInCodeCommandFunc,
  hasSentEmail,
  signInCommandFunc,
  isSignInInProgress,
  userDoesNotExist,
  eml,
  isHandlingSignInCodeInProgress,
}) => {
  const { code } = useParams<ParamProps>();

  const { fields, changeHandler } = useTypedFormFields<ISignInFormProps>({
    email: "",
  });

  useEffect(() => {
    if (code) {
      handleSignInCodeCommandFunc(code);
    }
  }, [code, handleSignInCodeCommandFunc]);

  const handleSignInSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    signInCommandFunc(fields.email);
  };

  const validateSignInForm = () => {
    return fields.email.length > 0;
  };

  const renderSignInForm = () => {
    return (
      <div className="container">
        <div className="col-sm">
          <Form onSubmit={handleSignInSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={fields.email}
                onChange={changeHandler}
              />
            </Form.Group>
            <LoaderButton
              block
              size="lg"
              type="submit"
              variant="success"
              isLoading={isSignInInProgress}
              disabled={!validateSignInForm()}
            >
              sign in
            </LoaderButton>
            {userDoesNotExist && (
              <Alert variant="warning">
                We could not find an account with that email address, click{" "}
                <a href="sign-up">here</a> to sign up.
              </Alert>
            )}
          </Form>
        </div>
      </div>
    );
  };

  function renderCheckEmail(email: string) {
    return (
      <div className="SignUp">
        <CheckEmail showAlreadyExists={false} email={email} />
      </div>
    );
  }

  function renderCheckingEmail() {
    return (
      <div className="SignUp">
        <Alert variant="info">Verifying the code and logging you in.</Alert>
      </div>
    );
  }

  return (
    <div className="SignIn">
      {hasSentEmail
        ? isHandlingSignInCodeInProgress
          ? renderCheckingEmail()
          : renderCheckEmail(eml)
        : renderSignInForm()}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    authTransientState: {
      email,
      isSignInInProgress,
      //hasSentEmail,
      userDoesNotExist,
      isHandlingSignInCodeInProgress,
    },
    authState: { hasSentEmail },
  } = state;

  return {
    hasSentEmail,
    isSignInInProgress,
    userDoesNotExist,
    eml: email!,
    isHandlingSignInCodeInProgress,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  handleSignInCodeCommandFunc: (code: string) =>
    dispatch(handleSignInCodeCommand(code)),

  signInCommandFunc: (email: string) => dispatch(signInCommand(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
