import React, { useState } from "react";
import "./Home.css";

import CreatePaymentComponent from "../components/CreatePayment";
import { Subject } from "rxjs";
import PaymentSubscriber from "../components/PaymentSubscriber";
import AllPayments from "../components/AllPayments";
import PaymentDeets from "../components/PaymentDeets";

const Home: React.FC = () => {
  const [showPayment, setShowPayment] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [paymentDeetsId, setPaymentDeetsId] = useState("");

  const paymentIdSubject = new Subject<string>();
  const selctedPaymentIdSubject = new Subject<string>();
  paymentIdSubject.subscribe((s) => {
    if (s === "reset") {
      setShowPayment(false);
    } else {
      setShowPayment(true);
      setPaymentId(s);
    }
  });

  selctedPaymentIdSubject.subscribe((s) => {
    setPaymentDeetsId(s);
  });

  return (
    <>
      <CreatePaymentComponent
        paymentIdObserver$={paymentIdSubject}
      ></CreatePaymentComponent>

      {showPayment ? (
        <PaymentSubscriber paymentId={paymentId}></PaymentSubscriber>
      ) : null}

      {paymentDeetsId === "" ? (
        <></>
      ) : (
        <PaymentDeets paymentId={paymentDeetsId}></PaymentDeets>
      )}

      <AllPayments paymentIdObserver$={selctedPaymentIdSubject}></AllPayments>
    </>
  );
};

export default Home;
