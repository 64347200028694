import { IAuthAdapter } from "@ontick/clients-common";
import { CognitoRefreshToken, CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { format } from "date-fns";

const authAdapter: IAuthAdapter = {
  authCreateCognitoUser: (username: string) =>
    (Auth as any).createCognitoUser(username),

  authSignInAsync: (email: string) => Auth.signIn(email),

  authSendCustomChallengeAnswerAsync: (user: CognitoUser, code: string) =>
    Auth.sendCustomChallengeAnswer(user, code),

  authSignUpAsync: (
    email: string,
    firstname: string,
    lastname: string,
    dateOfBirth: number
  ) =>
    Auth.signUp({
      username: email,
      password: Math.random().toString(20).substr(2, 12),
      attributes: {
        given_name: firstname,
        family_name: lastname,
        birthdate: format(new Date(dateOfBirth), "yyyy-MM-dd"),
      },
    }),

  authCurrentSessionAsync: () => Auth.currentSession(),
  authSignOutAsync: () => Auth.signOut(),
  cognitoRefreshTokenBuilder: (refreshToken: string) =>
    new CognitoRefreshToken({ RefreshToken: refreshToken }),
};

export { authAdapter };
