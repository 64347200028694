"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserConstants = void 0;
class UserConstants {
}
exports.UserConstants = UserConstants;
UserConstants.Statuses = (_a = class {
    },
    _a.USER = "USER",
    _a.VERIFIED_USER = "VERIFIED_USER",
    _a.FAILED = "FAILED",
    _a);
UserConstants.Types = (_b = class {
    },
    _b.UNVERIFIED = "UNVERIFIED",
    _b.GUEST_MEMBER = "GUEST_MEMBER",
    _b.CARD_CARRIER = "CARD_CARRIER",
    _b);
