"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.hideTermsAndConditionsCommand = exports.showTermsAndConditionsCommand = exports.loadTermsAndConditionsFailedEvent = exports.loadTermsAndConditionsSucceededEvent = exports.loadTermsAndConditionsCommand = exports.resetTermsAndConditionsStateCommand = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    termsAndConditions: undefined,
    showTermsAndConditions: false,
    error: undefined,
};
const termsAndConditionsSlice = toolkit_1.createSlice({
    name: "terms-and-conditions",
    initialState,
    reducers: {
        resetTermsAndConditionsStateCommand: () => {
            return Object.assign({}, initialState);
        },
        loadTermsAndConditionsCommand: (state) => {
            return Object.assign({}, state);
        },
        loadTermsAndConditionsSucceededEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { termsAndConditions: action.payload });
        },
        loadTermsAndConditionsFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { error: action.payload });
        },
        showTermsAndConditionsCommand: (state) => {
            return Object.assign(Object.assign({}, state), { showTermsAndConditions: true });
        },
        hideTermsAndConditionsCommand: (state) => {
            return Object.assign(Object.assign({}, state), { showTermsAndConditions: false });
        },
    },
});
_a = termsAndConditionsSlice.actions, exports.resetTermsAndConditionsStateCommand = _a.resetTermsAndConditionsStateCommand, exports.loadTermsAndConditionsCommand = _a.loadTermsAndConditionsCommand, exports.loadTermsAndConditionsSucceededEvent = _a.loadTermsAndConditionsSucceededEvent, exports.loadTermsAndConditionsFailedEvent = _a.loadTermsAndConditionsFailedEvent, exports.showTermsAndConditionsCommand = _a.showTermsAndConditionsCommand, exports.hideTermsAndConditionsCommand = _a.hideTermsAndConditionsCommand;
exports.default = termsAndConditionsSlice.reducer;
