import React, { FormEvent, useState } from "react";

import { gql, useMutation } from "@apollo/client";

import {
  createPayment,
  CreatePaymentMutation,
  CreatePaymentMutationVariables,
  CurrencyEnum,
  getPayments,
  PaymentTypeEnum,
} from "@ontick/appsync-client";

import { useAuthContext } from "./AuthContext";

import "./CreatePayment.css";

import { Form } from "react-bootstrap";
import { useTypedFormFields } from "../helpers/useTypedFormFields";
import LoaderButton from "./LoaderButton";
import { Observer } from "rxjs";
import CancelPayment from "./CancelPayment";

interface ICreatePaymentFormProps {
  amount: string;
}

interface ICreatePaymentProps {
  paymentIdObserver$: Observer<string>;
}

const CreatePayment: React.FC<ICreatePaymentProps> = ({
  paymentIdObserver$: paymentId$,
}) => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { user } = useAuthContext();

  const createPaymentMutation = gql(createPayment);

  const { fields, changeHandler } = useTypedFormFields<ICreatePaymentFormProps>(
    {
      amount: "0.00",
    }
  );

  const [savePayment, { error: savePaymentError, data: savePaymentData }] =
    useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(
      createPaymentMutation
    );

  const handleOnSubmitAsync = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    paymentId$.next("reset");

    setIsSubmittingForm(true);

    const result = await savePayment({
      variables: {
        input: {
          userId: user!.id,
          //amount: fields.amount,
          value: { amount: fields.amount, currency: CurrencyEnum.GBP },
          type: PaymentTypeEnum.EVEN_SPLIT,
        },
      },
      // this little bit of magic refetches all the payments after
      // a new one is created!
      refetchQueries: [
        // this is declared twice, in the list as well...
        { query: gql(getPayments), variables: { userId: user?.id } },
      ],
    });

    console.log("result: ", result);

    if (savePaymentError) {
      console.log(savePaymentError);
    } else {
      if (result.data?.createPayment) {
        paymentId$.next(result.data?.createPayment);
      }
    }

    setIsSubmittingForm(false);
  };

  const validateCreatePaymentForm = () => {
    return fields.amount.length > 0;
  };

  return (
    <div className="CreatePayment">
      <div className="container">
        {savePaymentError ? <p>Oh no! {savePaymentError.message}</p> : null}
        {savePaymentData && savePaymentData.createPayment ? (
          <>
            <p>Saved! - {savePaymentData.createPayment}</p>
            <CancelPayment
              paymentId={savePaymentData.createPayment}
            ></CancelPayment>
          </>
        ) : null}

        <Form onSubmit={handleOnSubmitAsync}>
          <Form.Group controlId="amount">
            <Form.Label>amount</Form.Label>
            <Form.Control
              autoFocus
              type="amount"
              value={fields.amount}
              onChange={changeHandler}
            />
          </Form.Group>

          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="success"
            isLoading={isSubmittingForm}
            disabled={!validateCreatePaymentForm()}
          >
            create
          </LoaderButton>
        </Form>
      </div>
    </div>
  );
};

export default CreatePayment;
