import { ChangeEvent, useState } from "react";

interface ITypedForm<T> {
  fields: T;
  changeHandler: (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
}

const useTypedFormFields = <T>(initialState: T): ITypedForm<T> => {
  const [fields, setValues] = useState<T>(initialState);

  const changeHandler = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    const {
      target: { id, value },
    } = event;

    setValues({
      ...fields,
      [id]: value,
    });
  };

  return {
    fields: fields,
    changeHandler,
  };
};

export { useTypedFormFields };
