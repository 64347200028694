"use strict";
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllMarqetaUsers = exports.getAllUsers = exports.getActivePayment = exports.getLiability = exports.getLiabilities = exports.getAccount = exports.getPayment = exports.getPayments = exports.getLatestTermsAndConditions = exports.getUser = void 0;
exports.getUser = `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      email
      dateOfBirth
      created
      updated
      currentVersion
      termsAndConditions {
        id
        timestamp
      }
      kyc {
        status
        timestamp
      }
      marqeta {
        userToken
        cardToken
        cardActivated
        timestamp
      }
    }
  }
`;
exports.getLatestTermsAndConditions = `
  query GetLatestTermsAndConditions {
    getLatestTermsAndConditions {
      id
      creator
      copy
      version
      created
    }
  }
`;
exports.getPayments = `
  query GetPayments($userId: String!) {
    getPayments(userId: $userId) {
      id
      amount
      value {
        amount
        currency
      }
      type
      members {
        id
        firstname
        lastname
        email
        share {
          amount
          currency
        }
      }
      status
      created
      updated
      completed
      currentVersion
      isCreator
    }
  }
`;
exports.getPayment = `
  query GetPayment($input: GetPaymentInput!) {
    getPayment(input: $input) {
      id
      amount
      value {
        amount
        currency
      }
      type
      members {
        id
        firstname
        lastname
        email
        share {
          amount
          currency
        }
      }
      status
      created
      updated
      completed
      currentVersion
      isCreator
    }
  }
`;
exports.getAccount = `
  query GetAccount($input: GetAccountInput!) {
    getAccount(input: $input) {
      id
      owner
      available
      balance
      creditLimit
      reserved
    }
  }
`;
exports.getLiabilities = `
  query GetLiabilities($input: GetLiabilitiesInput!) {
    getLiabilities(input: $input) {
      id
      paymentValue {
        amount
        currency
      }
      value {
        amount
        currency
      }
      paymentCreated
      status
    }
  }
`;
exports.getLiability = `
  query GetLiability($input: GetLiabilityInput!) {
    getLiability(input: $input) {
      id
      paymentValue {
        amount
        currency
      }
      value {
        amount
        currency
      }
      paymentCreated
      status
    }
  }
`;
exports.getActivePayment = `
  query GetActivePayment($cardToken: String!) {
    getActivePayment(cardToken: $cardToken) {
      id
      amount
      value {
        amount
        currency
      }
      type
      members {
        id
        firstname
        lastname
        email
        share {
          amount
          currency
        }
      }
      status
      created
      updated
      completed
      currentVersion
      isCreator
    }
  }
`;
exports.getAllUsers = `
  query GetAllUsers {
    getAllUsers {
      id
      firstname
      lastname
      email
      dateOfBirth
      created
      updated
      currentVersion
      termsAndConditions {
        id
        timestamp
      }
      kyc {
        status
        timestamp
      }
      marqeta {
        userToken
        cardToken
        cardActivated
        timestamp
      }
      roles
    }
  }
`;
exports.getAllMarqetaUsers = `
  query GetAllMarqetaUsers {
    getAllMarqetaUsers {
      token
      first_name
      last_name
      email
    }
  }
`;
