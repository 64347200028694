"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_observable_1 = require("redux-observable");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const terms_and_conditions_slice_1 = require("./terms-and-conditions.slice");
const user_slice_1 = require("../user/user.slice");
const ApiManager_1 = require("../../ApiManager");
const loadTermsAndConditionsCommandEpic$ = (action$, _rootState$, { apiAdapter }) => {
    const apiManager = new ApiManager_1.ApiManager(apiAdapter);
    return action$.pipe(operators_1.filter((action) => terms_and_conditions_slice_1.loadTermsAndConditionsCommand.match(action)), operators_1.mergeMap(() => {
        return rxjs_1.from(apiManager.fetchTermsAndConditionsAsync()).pipe(operators_1.map((result) => {
            if (result) {
                return terms_and_conditions_slice_1.loadTermsAndConditionsSucceededEvent(result);
            }
            else {
                return terms_and_conditions_slice_1.loadTermsAndConditionsFailedEvent("no found");
            }
        }), operators_1.catchError((error) => {
            return rxjs_1.of(terms_and_conditions_slice_1.loadTermsAndConditionsFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const usersNeedsToAcceptTermsAndConditionsEpic$ = (action$, rootState$) => {
    return action$.pipe(
    // fire this epic when the user is loaded and the t&c and loaded
    // one will fire before the other so we check for the objects in the state
    // and evaluate them
    operators_1.filter((action) => user_slice_1.userPushedEvent.match(action) ||
        user_slice_1.loadUserSucceededEvent.match(action) ||
        terms_and_conditions_slice_1.loadTermsAndConditionsSucceededEvent.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([, state]) => {
        const { userState: { user }, termsAndConditionsState: { termsAndConditions }, } = state;
        return { user, termsAndConditions };
    }), operators_1.map((x) => {
        var _a;
        const { user, termsAndConditions } = x;
        if (user && termsAndConditions) {
            if (((_a = user.termsAndConditions) === null || _a === void 0 ? void 0 : _a.id) !== termsAndConditions.id) {
                return terms_and_conditions_slice_1.showTermsAndConditionsCommand();
            }
        }
        return terms_and_conditions_slice_1.hideTermsAndConditionsCommand();
    }));
};
exports.default = redux_observable_1.combineEpics(loadTermsAndConditionsCommandEpic$, usersNeedsToAcceptTermsAndConditionsEpic$);
