"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createIdentityModel = void 0;
const types_1 = require("@aws-amplify/auth/lib/types");
const createIdentityModel = (userName) => {
    if (userName.includes("_")) {
        const usernameParts = userName.split("_");
        return {
            id: usernameParts[1],
            provider: usernameParts[0],
        };
    }
    return {
        id: userName,
        provider: types_1.CognitoHostedUIIdentityProvider.Cognito,
    };
};
exports.createIdentityModel = createIdentityModel;
