import "./index.css";

import Amplify, { Analytics, API, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";

import App from "./App";
import { Jumbotron } from "react-bootstrap";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { endpointFactory } from "./endpointFactory";

import config from "./config.json";

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
});

// Amplify creates this in clients-common but rather than use that
// we recreate it as we have all the values anyway
const awsExports = {
  aws_project_region: config.common.Region,
  aws_appsync_graphqlEndpoint: config.appSync.GraphQlApiUrl,
  aws_appsync_region: config.common.Region,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  graphql_headers: getIdToken
};
Amplify.configure(awsExports);

Auth.configure({
  mandatorySignIn: false,
  region: config.common.Region,
  userPoolId: config.auth.UserPoolId,
  identityPoolId: config.auth.IdentityPoolId,
  userPoolWebClientId: config.auth.WebUserPoolClientId,
  authenticationFlowType: "CUSTOM_AUTH",
  oauth: {
    domain: `ontick-${config.auth.Stage}.auth.${config.common.Region}.amazoncognito.com`,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    //redirectSignIn: config.oauth.REDIRECT_URI,
    //redirectSignOut: config.oauth.REDIRECT_URI,
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});



API.configure({
  endpoints: [
    {
      name: "kyc-admin",
      endpoint: endpointFactory(
        config.kyc.ApiGatewayId,
        config.common.Region,
        config.kyc.Stage
      ),
      region: config.common.Region,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
    {
      name: "marqeta-admin",
      endpoint: endpointFactory(
        config.marqeta.ApiGatewayId,
        config.common.Region,
        config.marqeta.Stage
      ),
      region: config.common.Region,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
  ],
});

const analyticsConfigure = {
  disabled: false,
  autoSessionRecord: true,
  AWSPinpoint: {
    appId: config.auth.PinpointId,
    region: config.common.Region,
    mandatorySignIn: false,
  },
};

console.log(analyticsConfigure);

Analytics.configure(analyticsConfigure);

const ShowOnlyOneAppInstance: React.FC = () => {
  Analytics.record({ name: "web started" });

  //Record a custom event
  Analytics.record({
    name: "Album",
    attributes: { genre: "Rock", year: "1989" },
  });

  const [showThisInstance, setShowThisInstance] = useState(true);
  const [instanceStartTime, setInstanceStartTime] = useState("");

  const newestInstanceStartTime = "newestInstanceStartTime";

  useEffect(() => {
    const setUpStorageHandler = () => {
      onstorage = (storageEvent: StorageEvent) => {
        if (storageEvent.key === newestInstanceStartTime) {
          if (instanceStartTime !== storageEvent.newValue)
            setShowThisInstance(false);
        }
      };
    };

    setUpStorageHandler();
  }, [instanceStartTime]);

  useEffect(() => {
    const startTime = Date.now().toString();
    setInstanceStartTime(startTime);
    localStorage.setItem(newestInstanceStartTime, startTime);
  }, [setInstanceStartTime]);

  return (
    <div className="App">
      {showThisInstance ? (
        <App />
      ) : (
        <Jumbotron>
          <h1>Ontick</h1>
          <p>
            The Ontick app is active in another window, please check any open
            tabs or browers to see where you are logged in.
          </p>
        </Jumbotron>
      )}
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ShowOnlyOneAppInstance />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
