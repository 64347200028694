import "./Users.css";

import React, { useCallback, useEffect, useState } from "react";

import { API } from "aws-amplify";
import { IAdminUserModel, mapItemToUserModel } from "@ontick/user-model";
import LoaderButton from "../../components/LoaderButton";
import { Table } from "react-bootstrap";
import { onError } from "../../helpers/onError";

import { gql, useLazyQuery } from "@apollo/client";

import { getAllUsers, GetAllUsersQuery } from "@ontick/appsync-client";

interface IAdminUserVerifyingModel extends IAdminUserModel {
  isActive: boolean;
}

const Users: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<IAdminUserVerifyingModel[]>([]);
  const [isVerifying, setIsVerifying] = useState(false);

  const handleQueryOnCompleted = (data: GetAllUsersQuery) => {
    console.log(data);

    const cpy = data.getAllUsers.slice();
    cpy.sort((a, b) => b.created - a.created);

    const users = cpy.map((u) => {
      const user = mapItemToUserModel(u) as IAdminUserVerifyingModel;

      if (u.roles) {
        user.roles = [];
        u.roles.forEach((r) => {
          user.roles?.push(r!);
        });
      }

      return user;
    });

    setUsers(users);
    setIsLoading(false);
  };

  const [getAllUsersQuery] =
    useLazyQuery<GetAllUsersQuery>(gql(getAllUsers), {
      onCompleted: handleQueryOnCompleted,
    });

  const getUsers = useCallback(() => {
    function getUsersWrapper() {
      setIsLoading(true);
      getAllUsersQuery();
      console.log("in here");
    }
    getUsersWrapper();
  }, [setIsLoading, getAllUsersQuery]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  async function handleSendKycPassed(id: string, e: MouseEvent) {
    if (isVerifying) return;

    e.preventDefault();
    // console.log(users);
    setIsVerifying(true);

    // 1. Make a shallow copy of the items
    const usersCopy = [...users];

    // 2. Make a shallow copy of the item you want to mutate
    const updatingUser = usersCopy.filter((x) => {
      return x.id === id;
    })[0];
    const index = usersCopy.indexOf(updatingUser);
    //let item = {...usersCopy[1]};
    const updatingUserCopy = { ...updatingUser };

    // 3. Replace the property you're intested in
    //item.name = 'newName';
    updatingUserCopy.isActive = true;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first

    usersCopy[index] = updatingUserCopy;

    // 5. Set the state to our new copy
    setUsers(usersCopy);

    const { firstname, lastname } = updatingUser;

    try {
      await sendKycPassed(id, firstname, lastname);
      getAllUsersQuery();
    } catch (e) {
      onError(e);
    }
  }

  function sendKycPassed(
    id: string,
    firstname: string,
    lastname: string
  ): Promise<any> {
    return API.post("kyc-admin", `/kyc-admin/pass`, {
      body: { id, firstname, lastname },
    });
  }

  const renderUsers = (
    users: IAdminUserVerifyingModel[],
    handleSendPassed: (id: string, e: MouseEvent) => Promise<void>
  ) => {
    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Auth Roles</th>
              <th>T&C</th>
              <th>Card Token</th>
              <th>Version</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {users.map(
              ({
                id,
                firstname,
                lastname,
                email,
                currentVersion,
                type,
                roles,
                isActive,
                marqeta,
                termsAndConditions,
              }) => (
                <tr key={id}>
                  <td>{id}</td>
                  <td>{firstname}</td>
                  <td>{lastname}</td>
                  <td>{email}</td>
                  <td>{type}</td>
                  <td>{roles?.join(", ")}</td>
                  <td>
                    {termsAndConditions ? termsAndConditions.timestamp : ""}
                  </td>
                  <td>{marqeta ? marqeta.cardToken : ""}</td>
                  <td>{currentVersion}</td>
                  <td>
                    {email !== "ian@ontick.com" && (
                      <LoaderButton
                        type="submit"
                        isLoading={isActive}
                        disabled={isLoading}
                        onClick={(e: MouseEvent) => handleSendPassed(id, e)}
                      >
                        pass KYC
                      </LoaderButton>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <div className="Users">
      <LoaderButton
        type="submit"
        variant="success"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => getAllUsersQuery()}
      >
        reload
      </LoaderButton>
      {isLoading ? (
        <h2 className="pb-3 mt-4 mb-3">loading...</h2>
      ) : (
        renderUsers(users, handleSendKycPassed)
      )}
    </div>
  );
};

export default Users;
