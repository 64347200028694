import "./App.css";

import { persistor, store } from "./redux/store";

import AuthContextProvider from "./components/AuthContext";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import { Provider } from "react-redux";
import Routes from "./Routes";
import TermsAndConditionsModal from "./components/TermsAndContitionsModal";

// we reference persistStore so TS can find PersistGate
// eslint-disable-next-line
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { Auth } from "aws-amplify";

import { createAuthLink, AUTH_TYPE, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import {
  //ApolloCache,
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";

import config from "./config.json";

const url = config.appSync.GraphQlApiUrl;
const region = config.common.Region;
const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => {
    const currentSession = await Auth.currentSession();
    const t = currentSession.getIdToken().getJwtToken() as string;
    console.log("id token: ", t);
    return t;
  },
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  headers: { Authorization: auth.jwtToken as string },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthContextProvider>
            <TermsAndConditionsModal></TermsAndConditionsModal>
            <NavBar />
            <div className="App container py-3">
              <Routes />
            </div>
            <Footer />
          </AuthContextProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
