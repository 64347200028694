import "./TermsAndConditions.css";

import React, { FormEvent, useEffect, useState } from "react";

import { Form } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../helpers/onError";
import { useTypedFormFields } from "../../helpers/useTypedFormFields";
import { ApiManager } from "@ontick/clients-common";
import { useAuthContext } from "../../components/AuthContext";
import { apiAdapter } from "../../redux/apiAdapter";

interface ITermsAndConditionsFormProps {
  version: string;
  copy: string;
  id: string;
}

interface ITermsAndConditions {
  readonly id: string;
  readonly version: string;
  readonly copy: string;
}

const TermsAndConditions: React.FC = () => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [latest, setLatest] = useState<ITermsAndConditions | undefined>(
    undefined
  );
  const apiManager = new ApiManager(apiAdapter);
  const { user } = useAuthContext();

  const {
    fields,
    changeHandler,
  } = useTypedFormFields<ITermsAndConditionsFormProps>({
    version: latest?.version ?? "",
    copy: latest?.copy ?? "",
    id: latest?.id ?? "",
  });

  useEffect(() => {
    const fetchLatest = async () => {
      const latestResult = await apiManager.fetchTermsAndConditionsAsync();
      console.log("latest: ", latestResult);

      if (latestResult) {
        const { id, copy, version } = latestResult;

        fields.id = id;
        fields.copy = copy;
        fields.version = version;
        setLatest(latestResult);
      }
    };

    fetchLatest();
  }, []);

  const handleCreateTermsAndConditionsSubmit = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    setIsSubmittingForm(true);

    // const version = {
    //   version: fields.version,
    //   copy: fields.copy,
    // };

    // console.log("post body: ", version);

    try {
      //await API.post("tc", "/terms-and-conditions", { body: version });
      await apiManager.createTermsAndConditionsAsync(
        user?.id!,
        fields.copy,
        fields.version
      );
    } catch (error) {
      onError(error);
    }

    setIsSubmittingForm(false);
  };

  const validateCreateTermsAndConditionsForm = () => {
    return fields.version.length > 0 && fields.copy.length > 0;
  };

  const rendervalidateCreateTermsAndConditionsForm = () => {
    return (
      <div className="TermsAndConditions">
        <div className="container">
          <Form onSubmit={handleCreateTermsAndConditionsSubmit}>
            <Form.Group controlId="id">
              <Form.Label>id</Form.Label>
              <Form.Control autoFocus type="id" value={fields.id} readOnly />
            </Form.Group>
            <Form.Group controlId="version">
              <Form.Label>version</Form.Label>
              <Form.Control
                autoFocus
                type="version"
                value={fields.version}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group controlId="copy">
              <Form.Label>copy</Form.Label>
              <Form.Control
                type="copy"
                as="textarea"
                rows={6}
                value={fields.copy}
                onChange={changeHandler}
              />
            </Form.Group>

            <LoaderButton
              block
              size="lg"
              type="submit"
              variant="success"
              isLoading={isSubmittingForm}
              disabled={!validateCreateTermsAndConditionsForm()}
            >
              create
            </LoaderButton>
          </Form>
        </div>
      </div>
    );
  };

  return rendervalidateCreateTermsAndConditionsForm();
};

export default TermsAndConditions;
