import { Nav, Navbar } from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import React from "react";
import { useAuthContext } from "./AuthContext";
import config from "../config.json";
import { Roles } from "@ontick/auth-model";



const NavBar: React.FC = () => {
  const { roles, user, signOut } = useAuthContext();

  return (
    <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
      <LinkContainer to="/">
        <Navbar.Brand className="font-weight-bold text-muted">
          {config.auth.Stage === "prod"
            ? "Ontick"
            : `Ontick (${config.auth.Stage})`}
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Nav activeKey={window.location.pathname}>
          {roles?.includes(Roles.CARD_CARRIER) ? (
            <>
              <LinkContainer to="/payment">
                <Nav.Link>payment</Nav.Link>
              </LinkContainer>
            </>
          ) : (
            <></>
          )}

          {roles?.includes(Roles.ADMIN) ? (
            <>
              <LinkContainer to="/admin/marqeta-users">
                <Nav.Link>marqeta users</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/admin/users">
                <Nav.Link>users</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/admin/terms-and-conditions">
                <Nav.Link>terms and conditions</Nav.Link>
              </LinkContainer>
            </>
          ) : (
            <></>
          )}

          {roles ? (
            <>
              {/* <LinkContainer to="/settings">
                <Nav.Link>settings</Nav.Link>
              </LinkContainer> */}
              <Nav.Link onClick={signOut}>sign out</Nav.Link>
            </>
          ) : (
            <>
              <LinkContainer to="/sign-up">
                <Nav.Link>sign up</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/sign-in">
                <Nav.Link>sign in</Nav.Link>
              </LinkContainer>
            </>
          )}

          {user ? (
            <>
              <Navbar.Text>
                signed in as:
                <a href="#login">{`${user.firstname} ${user.lastname}`}</a>
                <span>[ {user? user.id: ""} ]</span>
              </Navbar.Text>
             
            </>
          ) : (
            <></>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
