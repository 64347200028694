import React, {  } from "react";

import { gql, useSubscription } from "@apollo/client";
import {
  PaymentSubscription,
  payment,
  PaymentSubscriptionVariables,
} from "@ontick/appsync-client";


import "./PaymentSubscriber.css";
import { Alert } from "react-bootstrap";

interface IPaymentSubscriberProps {
  paymentId: string;
}

const PaymentSubscriber: React.FC<IPaymentSubscriberProps> = ({
  paymentId,
}) => {
  const paymentSubscription = gql(payment);

  const { data, loading, error } = useSubscription<
    PaymentSubscription,
    PaymentSubscriptionVariables
  >(paymentSubscription, { variables: { id: paymentId } });

  return (
    <div className="PaymentSubscriber">
      <div className="container">
        {error ? <Alert variant="warning">{error}</Alert> : null}
        {loading ? (
          <Alert variant="warning">LOADING</Alert>
        ) : (
          <div>{JSON.stringify(data, null, 2)}</div>
        )}
      </div>
    </div>
  );
};

export default PaymentSubscriber;
