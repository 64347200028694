"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.connectGqlSubscriptionPaymentFailedEvent = exports.connectGqlSubscriptionPaymentSucceedEvent = exports.connectGqlSubscriptionPaymentCommand = exports.connectGqlSubscriptionUserFailedEvent = exports.connectGqlSubscriptionUserSucceedEvent = exports.connectGqlSubscriptionUserCommand = exports.resetGqlSubscriptionStateCommand = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isConnectingUser: false,
    isConnectedUser: false,
    isConnectingPayment: false,
    isConnectedPayment: false,
    error: undefined,
};
const gqlSubscriptionSlice = toolkit_1.createSlice({
    name: "gql-subscription",
    initialState,
    reducers: {
        resetGqlSubscriptionStateCommand: () => {
            return Object.assign({}, initialState);
        },
        connectGqlSubscriptionUserCommand: (state, _action) => {
            return Object.assign(Object.assign({}, state), { isConnectingUser: true });
        },
        connectGqlSubscriptionUserSucceedEvent: (state) => {
            return Object.assign(Object.assign({}, state), { isConnectingUser: false, isConnectedUser: true });
        },
        connectGqlSubscriptionUserFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { isConnectingUser: false, error: action.payload });
        },
        connectGqlSubscriptionPaymentCommand: (state, _action) => {
            return Object.assign(Object.assign({}, state), { isConnectingPayment: true });
        },
        connectGqlSubscriptionPaymentSucceedEvent: (state) => {
            return Object.assign(Object.assign({}, state), { isConnectingPayment: false, isConnectedPayment: true });
        },
        connectGqlSubscriptionPaymentFailedEvent: (state, action) => {
            return Object.assign(Object.assign({}, state), { isConnectingPayment: false, error: action.payload });
        },
    },
});
_a = gqlSubscriptionSlice.actions, exports.resetGqlSubscriptionStateCommand = _a.resetGqlSubscriptionStateCommand, exports.connectGqlSubscriptionUserCommand = _a.connectGqlSubscriptionUserCommand, exports.connectGqlSubscriptionUserSucceedEvent = _a.connectGqlSubscriptionUserSucceedEvent, exports.connectGqlSubscriptionUserFailedEvent = _a.connectGqlSubscriptionUserFailedEvent, exports.connectGqlSubscriptionPaymentCommand = _a.connectGqlSubscriptionPaymentCommand, exports.connectGqlSubscriptionPaymentSucceedEvent = _a.connectGqlSubscriptionPaymentSucceedEvent, exports.connectGqlSubscriptionPaymentFailedEvent = _a.connectGqlSubscriptionPaymentFailedEvent;
//export type { IConnect };
exports.default = gqlSubscriptionSlice.reducer;
