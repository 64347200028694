import {
  persistorFactory,
  storeFactory,
  IConfig,
} from "@ontick/clients-common";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { authAdapter } from "./authAdapter";
import { apiAdapter } from "./apiAdapter";
import { analyticsAdapter } from "./analyticsAdapter";

import configJson from "../config.json";

const config: IConfig = {
  region: configJson.common.Region,
  userPoolId: configJson.auth.UserPoolId,
  identityPoolId: configJson.auth.IdentityPoolId,
  iosNotificationsSandbox: false,
};
const logRedux = true;
const store = storeFactory(
  authAdapter,
  apiAdapter,
  analyticsAdapter,
  storage,
  config,
  logRedux
);
const persistor = persistorFactory(store);

type AppSubscribe = typeof store.subscribe;
type AppDispatch = typeof store.dispatch;

export type { AppSubscribe, AppDispatch };
export { store, persistor };
