"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const auth_slice_1 = require("./auth.slice");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const user_slice_1 = require("../user/user.slice");
const AuthManager_1 = require("./AuthManager");
const redux_observable_1 = require("redux-observable");
const gql_subscription_slice_1 = require("../gql-subscription/gql-subscription.slice");
const terms_and_conditions_slice_1 = require("../terms-and-conditions/terms-and-conditions.slice");
const CommonConstants_1 = require("../../CommonConstants");
const signUpCommandEpic$ = (action$, _rootState$, { authAdapter: { authSignUpAsync } }) => {
    return action$.pipe(operators_1.filter((action) => auth_slice_1.signUpCommand.match(action)), operators_1.mergeMap((action) => {
        const { payload: { email, firstname, lastname, dateOfBirth }, } = action;
        return rxjs_1.from(authSignUpAsync(email, firstname, lastname, dateOfBirth)).pipe(operators_1.map(() => {
            return auth_slice_1.signInAndStoreSessionCommand(false);
        }), operators_1.catchError((error) => {
            if (error.code === "UsernameExistsException") {
                return rxjs_1.of(auth_slice_1.signInAndStoreSessionCommand(true));
            }
            console.log("error: ", error);
            return rxjs_1.of(auth_slice_1.signUpFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const signInAndStoreSessionCommandEpic$ = (action$, rootState$, { authAdapter, config }) => {
    const { signInAsync } = new AuthManager_1.AuthManager(authAdapter, config);
    return action$.pipe(operators_1.filter((action) => auth_slice_1.signInAndStoreSessionCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([, state]) => {
        const { authTransientState: { email }, } = state;
        return email;
    }), operators_1.mergeMap((email) => {
        if (email) {
            return rxjs_1.from(signInAsync(email)).pipe(operators_1.map((cognitoUser) => {
                return auth_slice_1.signInEmailSentEvent(cognitoUser);
            }), operators_1.catchError((error) => {
                console.log("error: ", error);
                return rxjs_1.of(auth_slice_1.signUpFailedEvent(JSON.stringify(error)));
            }));
        }
        else {
            return rxjs_1.of(auth_slice_1.signUpFailedEvent("email not found"));
        }
    }));
};
const signInCommandEpic$ = (action$, _rootState$, { authAdapter, config }) => {
    const { signInAsync } = new AuthManager_1.AuthManager(authAdapter, config);
    return action$.pipe(operators_1.filter((action) => auth_slice_1.signInCommand.match(action)), operators_1.mergeMap((action) => {
        const { payload: email } = action;
        return rxjs_1.from(signInAsync(email)).pipe(operators_1.map((cognitoUser) => {
            return auth_slice_1.signInEmailSentEvent(cognitoUser);
        }), operators_1.catchError((error) => {
            if (error.code === "UserNotFoundException") {
                return rxjs_1.of(auth_slice_1.userDoesNotExistEvent());
            }
            console.log("error: ", error);
            return rxjs_1.of(auth_slice_1.signInFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const signOutCommandEpic$ = (action$, _rootState$, { authAdapter: { authSignOutAsync } }) => {
    return action$.pipe(operators_1.filter((action) => auth_slice_1.signOutCommand.match(action)), operators_1.mergeMap(() => {
        return rxjs_1.from(authSignOutAsync()).pipe(operators_1.mergeMap(() => {
            return rxjs_1.of(auth_slice_1.signOutSucceededEvent(), user_slice_1.resetUserStateCommand(), terms_and_conditions_slice_1.resetTermsAndConditionsStateCommand(), auth_slice_1.clearCognitoIdentityCredentialsCommand());
        }), operators_1.catchError((error) => {
            console.log("error: ", error);
            return rxjs_1.of(auth_slice_1.signOutFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const clearCognitoIdentityCredentialsCommandEpic$ = (action$, rootState$, { authAdapter, config }) => {
    const { clearCognitoIdentityCredentials } = new AuthManager_1.AuthManager(authAdapter, config);
    return action$.pipe(operators_1.filter((action) => auth_slice_1.clearCognitoIdentityCredentialsCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([_, state]) => {
        const { authState: { idToken }, } = state;
        clearCognitoIdentityCredentials(idToken);
        return auth_slice_1.cognitoIdentityCredentialsClearedEvent();
    }));
};
const refreshSessionCommandEpic$ = (action$, rootState$, { authAdapter, config }) => {
    const { refreshSessionAsync } = new AuthManager_1.AuthManager(authAdapter, config);
    return action$.pipe(operators_1.filter((action) => auth_slice_1.refreshSessionCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([_, state]) => {
        const { authState: { cognitoUser, refreshToken }, } = state;
        return {
            cognitoUser,
            refreshToken,
        };
    }), operators_1.mergeMap((x) => {
        const { cognitoUser, refreshToken } = x;
        return rxjs_1.from(refreshSessionAsync(cognitoUser, refreshToken)).pipe(operators_1.map((sessiondata) => {
            if (sessiondata) {
                return auth_slice_1.refreshSessionSucceededEvent(sessiondata);
            }
            return auth_slice_1.refreshSessionFailedEvent("");
        }), operators_1.catchError((error) => {
            console.log("error: ", error);
            return rxjs_1.of(auth_slice_1.refreshSessionFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const handleSignInCodeCommandEpic$ = (action$, rootState$, { authAdapter, config }) => {
    const { rehydrateContigoUser, sendCustomChallengeAnswerAsync } = new AuthManager_1.AuthManager(authAdapter, config);
    return action$.pipe(operators_1.filter((action) => auth_slice_1.handleSignInCodeCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([action, state]) => {
        const { authState: { cognitoUser, isSignUpInProgress }, } = state;
        const { payload } = action;
        if (cognitoUser) {
            const user = rehydrateContigoUser(cognitoUser);
            return {
                user: user,
                code: payload,
                isSignUpInProgress: isSignUpInProgress,
            };
        }
        else {
            return { user: undefined, code: payload };
        }
    }), operators_1.mergeMap((x) => {
        const { user, code, isSignUpInProgress } = x;
        console.log("isSignUpInProgress: ", isSignUpInProgress);
        if (user) {
            return rxjs_1.from(sendCustomChallengeAnswerAsync(user, code)).pipe(operators_1.mergeMap((result) => {
                if (result) {
                    if (isSignUpInProgress) {
                        return rxjs_1.of(gql_subscription_slice_1.connectGqlSubscriptionUserCommand(result.preferredUsername), auth_slice_1.signInSucceededEvent(result), auth_slice_1.fetchIdentityIdCommand(), terms_and_conditions_slice_1.loadTermsAndConditionsCommand(), auth_slice_1.analyticsUpateEndpointCommand(result.preferredUsername), 
                        // when its a new user, there is a race between the backend user service creating the user in the db
                        // and the gql subscription being attached. If the subscription is before the user is created,
                        // then we are all good, if not we need a way to fetch the new user, hence this nasty poll command
                        user_slice_1.pollForNewUserCommand(result.preferredUsername));
                    }
                    else {
                        return rxjs_1.of(gql_subscription_slice_1.connectGqlSubscriptionUserCommand(result.preferredUsername), auth_slice_1.signInSucceededEvent(result), auth_slice_1.fetchIdentityIdCommand(), terms_and_conditions_slice_1.loadTermsAndConditionsCommand(), auth_slice_1.analyticsUpateEndpointCommand(result.preferredUsername), user_slice_1.loadUserCommand(result.preferredUsername));
                    }
                }
                return rxjs_1.of(auth_slice_1.signInFailedEvent(""));
            }), operators_1.catchError((error) => {
                console.log("error: ", error);
                return rxjs_1.of(auth_slice_1.signInFailedEvent(JSON.stringify(error)));
            }));
        }
        else {
            return rxjs_1.of(auth_slice_1.signInFailedEvent(""));
        }
    }));
};
const fetchIdentityIdCommandEpic$ = (action$, rootState$, { config, authAdapter }) => {
    const { fetchIdentityIdAsync } = new AuthManager_1.AuthManager(authAdapter, config);
    return action$.pipe(operators_1.filter((action) => auth_slice_1.fetchIdentityIdCommand.match(action)), operators_1.withLatestFrom(rootState$), operators_1.map(([_, state]) => {
        const { authState: { idToken, accessToken }, } = state;
        return {
            idToken: idToken,
            accessToken: accessToken,
        };
    }), operators_1.mergeMap((action) => {
        const { idToken } = action;
        return rxjs_1.from(fetchIdentityIdAsync(idToken)).pipe(operators_1.mergeMap((identityId) => {
            return rxjs_1.of(auth_slice_1.fetchIdentityIdSucceededEvent(identityId));
        }), operators_1.catchError((error) => {
            console.log("error: ", error);
            return rxjs_1.of(auth_slice_1.fetchIdentityIdFailedEvent(JSON.stringify(error)));
        }));
    }));
};
const analyticsUpateEndpointCommandEpic$ = (action$, _rootState$, { analyticsAdapter: { analyticsUpdateEndpoint }, storage, config: { iosNotificationsSandbox }, }) => {
    return action$.pipe(operators_1.filter((action) => auth_slice_1.analyticsUpateEndpointCommand.match(action)), operators_1.mergeMap((action) => {
        const { payload } = action;
        return rxjs_1.from(storage.getItem(CommonConstants_1.CommonConstants.PUSH_NOTIFICATION_TOKEN)).pipe(operators_1.mergeMap((token) => {
            console.log("push notification token: ", token);
            const attrs = token
                ? iosNotificationsSandbox
                    ? {
                        userId: payload,
                        address: token,
                        optOut: "NONE",
                        channelType: "APNS_SANDBOX",
                    }
                    : {
                        userId: payload,
                        address: token,
                        optOut: "NONE",
                    }
                : { userId: payload };
            return rxjs_1.from(analyticsUpdateEndpoint(attrs)).pipe(operators_1.mergeMap((x) => {
                console.log(x);
                return rxjs_1.of(auth_slice_1.analyticsUpateEndpointSucceededEvent());
            }));
        }), operators_1.catchError((error) => {
            console.log("error: ", error);
            return rxjs_1.of(auth_slice_1.analyticsUpateEndpointCommand(JSON.stringify(error)));
        }));
    }));
};
exports.default = redux_observable_1.combineEpics(signUpCommandEpic$, signInAndStoreSessionCommandEpic$, handleSignInCodeCommandEpic$, signInCommandEpic$, fetchIdentityIdCommandEpic$, signOutCommandEpic$, clearCognitoIdentityCredentialsCommandEpic$, refreshSessionCommandEpic$, analyticsUpateEndpointCommandEpic$);
