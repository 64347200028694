"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGqlSubscriptionMiddleware = void 0;
const ApiManager_1 = require("../../ApiManager");
const payment_slice_1 = require("../payment/payment.slice");
const user_slice_1 = require("../user/user.slice");
const gql_subscription_slice_1 = require("./gql-subscription.slice");
const createGqlSubscriptionMiddleware = (apiAdapter) => {
    let userSubscription;
    let paymentSubscription;
    const apiManager = new ApiManager_1.ApiManager(apiAdapter);
    const gqlSubscriptionMiddleware = ({ dispatch, }) => (next) => async (action) => {
        // next the current action immediatley
        const result = next(action);
        const setUpSubscriptionUser = (userId) => {
            try {
                // subscribe to user updates here
                userSubscription = apiManager.user$(userId).subscribe({
                    next: (user) => {
                        dispatch(user_slice_1.userPushedEvent(user));
                    },
                    error: (e) => {
                        console.error(e);
                    },
                });
                console.log(userSubscription);
                dispatch(gql_subscription_slice_1.connectGqlSubscriptionUserSucceedEvent());
            }
            catch (error) {
                dispatch(gql_subscription_slice_1.connectGqlSubscriptionUserFailedEvent(JSON.stringify(error)));
            }
        };
        const setUpSubscriptionPayment = (paymentId) => {
            try {
                // subscribe to payment updates here
                paymentSubscription = apiManager.payment$(paymentId).subscribe({
                    next: (payment) => {
                        console.log("payment: ", payment);
                        dispatch(payment_slice_1.paymentPushedEvent(payment));
                    },
                    error: (e) => {
                        console.log(e);
                    },
                });
                console.log(paymentSubscription);
                dispatch(gql_subscription_slice_1.connectGqlSubscriptionPaymentSucceedEvent());
            }
            catch (error) {
                dispatch(gql_subscription_slice_1.connectGqlSubscriptionPaymentFailedEvent(JSON.stringify(error)));
            }
        };
        switch (action.type) {
            case gql_subscription_slice_1.connectGqlSubscriptionUserCommand("").type:
                setUpSubscriptionUser(action.payload);
                break;
            case gql_subscription_slice_1.connectGqlSubscriptionPaymentCommand("").type:
                setUpSubscriptionPayment(action.payload);
                break;
            default:
                break;
        }
        return result;
    };
    return gqlSubscriptionMiddleware;
};
exports.createGqlSubscriptionMiddleware = createGqlSubscriptionMiddleware;
